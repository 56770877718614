import request from "@/utils/request"


// 查询招标项目信息列表
export function listProject(query) {
    return request({
      url: '/api/portal/project/portalList',
      method: 'get',
      params: query
    })
}

// 查询中标公示项目信息列表
export function listBid(query) {
  return request({
    url: '/api/portal/publicity/portalList',
    method: 'get',
    params: query
  })
}

// 上传附件
export function addAttach(data,header) {
  return request({
    url: '/api/portal/message/uploadAttach',
    method: 'post',
    data: data,
    headers:header
  })
}

// 查询【请填写功能名称】详细
export function getProject(projectId) {
    return request({
      url: '/api/portal/project/getProjectById/' + projectId,
      method: 'get'
    })
}

// 查询【请填写功能名称】详细
export function getPublicity(winBidId) {
  return request({
    url: '/api/portal/publicity/getPublicityById/' + winBidId,
    method: 'get'
  })
}

// 新增【请填写功能名称】
export function addMessage(data) {
  return request({
    url: '/api/portal/message/portalAdd',
    method: 'post',
    data: data
  })
}