<template>
  <Layout>
    <div class='section'>
      <div class='container'>

        <!-- 首页图片轮播层 -->
        <div class='banner-container'>
          <div class='am-g'>
            <el-carousel class='banner-img'>
              <el-carousel-item v-for="(item, key) in bannerImgList" :key="key">
                <img :src="imgUrl + item.cover" alt='loading...' class="banner-img">
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>

        <!-- 产业动态层 -->
        <div class='industry-container'>
          <div class="item-container left-container">
            <div class="item-title">
              <span>产业动态</span>
              <a style="float: right; padding: 3px 0; cursor: pointer;" @click.prevent="handleMore">更多></a>
            </div>

            <div class="item-list" v-for="(item, key) in this.newsLeftList" :key="key">
              <div class="item-list-img" v-if="item.cover">
                <img :src="imgUrl + item.cover" alt="loading..." class="img_fluid" style="height: 215px;">
              </div>
              <div class="item-list-info" :style="{width:item.cover?'48%':'100%'}">
                <div class="item-list-info-title">
                  <div class="item-list-info-title-top p-text-ellipsis">
                    <p>{{ item.newsTitle }}</p>
                    <p>{{ item.newsSubheading }}</p>
                  </div>
                  <div class="item-list-info-title-bottom">
                    {{ timestampToTime(item.registrationTime) }}
                  </div>
                </div>
                <div class="item-list-info-text">
                  <div class="item-list-info-text-top text-ellipsis">
                    {{ htmlToString(item.newsMainBody) }}
                  </div>
                  <div class="item-list-info-text-bottom">
                    <a style="cursor: pointer;" @click.prevent="handleNewsDetail(item)"> <span>详情</span> > </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="item-container right-container">

            <div class="item-title">
              <span>产业动态</span>
              <a style="float: right; padding: 3px 0; cursor: pointer;" @click.prevent="handleMore">更多></a>
            </div>

            <!-- <div class="item-list">
              <div class="item-list-img">
                <img :src="newImg" alt="loading..." class="img_fluid">
              </div>
              <div class="item-list-info">
                <div class="item-list-info-title">
                  <div class="item-list-info-title-top">
                    <p>产业革新 数字赋能</p>
                    <p>常德米粉产业数字化暨智慧监管平台建设正式启动</p>
                  </div>
                  <div class="item-list-info-title-bottom">2020年9月1日</div>
                </div>
                <div class="item-list-info-text">
                  <div class="item-list-info-text-top">
									  2020年7月30日下午，常德米粉产业数字化暨智慧监管平台建设启动仪式在常德顺利举行，
                    常德市人民政府副市长...
								  </div>
                  <div class="item-list-info-text-bottom">
                    <a href> <span>详情</span> > </a>
                  </div>
                </div>
              </div>
            </div> -->

            <div class="item-list" v-for="(item, key) in this.newsRightList" :key="key">
              <div class="item-list-img" v-if="item.cover">
                <img :src="imgUrl + item.cover" alt="loading..." class="img_fluid" style="height: 215px;">
              </div>
              <div class="item-list-info" :style="{width:item.cover?'48%':'100%'}">
                <div class="item-list-info-title">
                  <div class="item-list-info-title-top">
                    <p>{{ item.newsTitle }}</p>
                    <p>{{ item.newsSubheading }}</p>
                  </div>
                  <div class="item-list-info-title-bottom">
                    {{ timestampToTime(item.registrationTime) }}
                  </div>
                </div>
                <div class="item-list-info-text">
                  <div class="item-list-info-text-top text-ellipsis">
                    {{ htmlToString(item.newsMainBody) }}
                  </div>
                  <div class="item-list-info-text-bottom">
                    <a style="cursor: pointer;" @click.prevent="handleNewsDetail(item)"> <span>详情</span> > </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 视频层 -->
        <div class="video-container">

          <div class="video-left">
            <video autoplay muted controls
              :src="imgUrl + videoUrl"></video>
          </div>

          <div class="video-right">
            <a href>
              <img :src="videoImg" alt="loading...">
            </a>
          </div>

        </div>

        <!-- 追溯系统层-->
        <div class="trace-container">
          <div class="trace-item-box">
            <p>常德米粉质量安全体系追溯系统</p>
            <p>常德米粉溯源认证查询通道</p>
            <p>（功能框，输入企业信息可查询是否加入产业溯源认证体系）</p>
          </div>
        </div>

        <!--  技术公示层  -->
        <div class="publicity-container">
          <div class="publicity-item-box">
            <p>常德米粉出品技术标准公示通道</p>
            <p>（点击进入技术标准详情）</p>
          </div>
        </div>

        <!-- 产业联盟层 -->
        <div class="alliance-container">
          <div class="alliance-item-box">
            <p>产业品牌联盟 排名不分先后</p>
            <p>（点击进入品牌官网）</p>
            <div class="alliance-item-list">
              <a :href="item.outUrl" class="a-item" v-for="item in ppdqList">
                <img :src="imgUrl + item.cover" class="img_fluid"
                  alt="loading...">
              </a>
            </div>
          </div>
        </div>

        <!-- 监管层 -->
        <div class="supervise-container">
          <div class="item-supervise">
            <p>官方链接</p>
            <p>市政府、监督局、质监局、发改委</p>
          </div>
          <div class="item-report">
            <p>产业监管在线举报通道</p>
            <p>日期、举报人、邮箱、联系电话</p>
            <p>被举报企业、投诉内容</p>
          </div>
        </div>

      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/components/common/Layout';
import bannerImg from '@/assets/images/cdmf-img/index-img/banner.png';
import newImg from '@/assets/images/cdmf-img/index-img/newimg.png';
import videoTextImg from '@/assets/images/cdmf-img/index-img/videotext.png';

import { getNewsList, getVideo } from '@/api/backstage/company_news';
import { parseTime } from '@/utils/common';
export default {
  name: 'IndexView',
  components: { Layout },
  data() {
    return {
      bannerImgList: [],
      newImg: newImg,
      videoImg: videoTextImg,

      queryParams: {
        newsType: 1,  // 产业动态
        newsState: 1,
      },

      videoQueryParams: {
        fileState: 1,
      },

      newsList: [],
      newsLeftList: [],
      newsRightList: [],
      ppdqList:[],
      imgUrl: process.env.VUE_APP_FILE_SERVER,
      videoUrl: '',
    };
  },

  mounted() {
  },

  created() {
    this.getTopPicture();
    this.getNewsListInfo();
    this.getVideoInfo();
    this.getPpqd();
  },

  methods: {
    getTopPicture(){
      let param = { newsType: 3,newsState: 1};
      getNewsList(param).then(resp => {
        this.bannerImgList = [];
        if (resp.rows && resp.rows.length > 0) {
          this.bannerImgList = resp.rows;
        }
      }).catch(error => {

      });
    },
    getPpqd(){
      let param = { newsType: 4,newsState: 1};
      getNewsList(param).then(resp => {
        this.ppdqList = [];
        if (resp.rows && resp.rows.length > 0) {
          this.ppdqList = resp.rows;
        }
      }).catch(error => {

      });
    },
    getNewsListInfo() {
      let param = { newsType: 1,newsState: 1};
      getNewsList(param).then(resp => {
        this.newsList = resp.rows;
        let temp = [];
        if (this.newsList.length >= 2) {
          temp = this.cutToScale(this.newsList, 2);
           this.newsLeftList = temp[0];
          this.newsRightList = temp[1];
        } else {
          this.newsLeftList = this.newsList;
        }
      }).catch(error => {
        // this.$message({
        //   showClose: true,
        //   message: '网络延时，请刷新重试',
        //   type: 'warning',
        //   customClass: 'mzindex'
        // });
      });
    },

    /** 获取视频 */
    getVideoInfo() {
      getVideo(this.videoQueryParams).then(resp => {
        this.videoUrl = resp.rows[0].filePath;
      }).catch(() => {
        // this.$message({
        //   showClose: true,
        //   message: '网络延时，请刷新重试',
        //   type: 'warning',
        //   customClass: 'mzindex'
        // });
      });
    },

    htmlToString(htmlStr) {
      if (!htmlStr) {
        return ;
      }
      return this.removeHtmlSign(this.escape2Html(htmlStr));
    },

    /** 将html文本转换为字符串 */
    removeHtmlSign(htmlStr) {
      return htmlStr.replace(/<\/?.+?\/?>|\r|\n|\s*/g, '');
      // 去掉html标签
      // htmlStr.replace(/<\/?.+?\/?>/g,'')
      // 去掉空格、换行
      // htmlStr.replace(/\r|\n|\s/g,'')
    },

    // 转义符换成普通字符
    escape2Html(str) {
      if (!str) {
        return ;
      }
      let arrEntities = { 'lt': '<', 'gt': '>', 'nbsp': ' ', 'amp': '&', 'quot': '"' };
      return str.replace(
        /&(lt|gt|nbsp|amp|quot);/ig,
        function (all, t) {
          return arrEntities[t];
        }
      );
    },

    /* 时间戳转换为时间 */
    timestampToTime(timestamp) {
      return parseTime(timestamp, '{y}年{m}月{d}日');
    },

    /**
     * 按比列切割数组，2个一组分割数组
     * @param {*} arr 数组
     * @param {*} proportion 切割比列
     */
    cutToScale(arr, proportion) {
      if (!arr || arr.length == 0) {
        return ;
      }
      // debugger;
      let num = 0;
      let _data = [[],[]];
      for (let i = 0; i < arr.length; i++) {
        if (i % proportion == 0) {
          _data[0].push(arr[i]);
        } else {
          _data[1].push(arr[i]);
        }
      }
      return _data;
    },

    /** 更多按钮操作 */
    handleMore() {
      // vue路由跳转 打开新标签页
      // let routeData = this.$router.resolve({
      //   path: "/newsMore",
      //   query: {newsType: this.queryParams.newsType}
      // });
      // window.open(routeData.href, '_blank');
      this.$router.push({
        path: "/newsMore",
        query: {newsType: this.queryParams.newsType}
      });
    },

    /** 详情操作按钮 */
    handleNewsDetail(item) {
      this.$router.push({
        path: "/newsDetail",
        query: {id: item.newsId}
      });
    }

  },
};
</script>

<style scoped>
.section {
  padding: 0;
  width: 100%;
  height: 100%;
}

img {
  vertical-align: middle;
  border-style: none;
}

.section .container {
  width: 100%;
  height: 100%;
}

/* 图片轮播层样式 */
.section .banner-container {
  margin-top: 0;
  width: 100%;
  height: 100%;
}

.banner-container .am-g {
  width: 100%;
  height: 100%;
}

::v-deep .am-g .el-carousel__container {
  height: 860px;
}

.banner-img {
  width: 100%;
  height: 100%;
}

/* 产业动态主容器层样式 */
.container .industry-container {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 60px 4%;
}

/* 产业动态左/右容器层样式 */
.industry-container .left-container,
.right-container {
  width: 44%;
  /* height: 75vh; */
}

/* 产业动态标题层共用样式 */
.industry-container .item-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 20px 0px;
  border-bottom: 1px solid #a5a5a5;
}

.item-title span {
  font-size: 24px;
  font-weight: bold;
}

.item-title a {
  font-size: 20px;
}

.item-list {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
}

.item-list-img {
  width: 49%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.item-list-info {
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.img_fluid {
  width: 100%;
  height: auto;
}

.item-list-info-title,
.item-list-info-text {
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: #BC0B21;
  width: 100%;
}

.item-list-info-title p {
  font-size: 14px;
  color: #DFBE81;
  margin: 0px;
}

.item-list-info-title-top {
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px 0px 5px 0px;
  border-bottom: 1px solid #ccc;
}

.p-text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.item-list-info-title-bottom {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  margin: 0px;
  color: #fff;
  padding-top: 5px;
}

.item-list-info-text-top {
  color: #Fff;
  height: 94px;
  font-size: 14px;
  padding: 0px 0px 5px 0px;
  border-bottom: 1px solid #ccc;
}

/** 超出省略号 */
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.item-list-info-text-bottom {
  padding: 1px 0 0px 0px;
}

.item-list-info-text-bottom a {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: 14px;
}

.item-list-info-text-bottom a:hover {
  color: blue;
}


/* 视频层样式 */
.video-container {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px auto;
  padding: 0px 0px 60px 0px;
}

.video-left {
  width: 60%;
  display: flex;
}

.video-left video {
  width: 100%;
  height: auto;
}

.video-right {
  width: 40%;
  background-color: #A52228;
}

.video-right a {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.video-right img {
  width: 78%;
  height: auto;
}

/* 追溯层样式 */
.trace-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 60px 0px;
}

.trace-item-box {
  width: 85%;
  border-radius: 30px;
  background-color: #A52228;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.trace-item-box p {
  font-size: 24px;
  color: #DFBE81;
  margin: 0px;
}

/* 技术公示层样式 */
.publicity-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 60px 0px;
}

.publicity-item-box {
  width: 85%;
  border-radius: 30px;
  background-color: #A52228;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.publicity-item-box p {
  font-size: 24px;
  color: #DFBE81;
  margin: 0px;
}

/* 产业联盟层样式 */
.alliance-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 60px 0px;
}

.alliance-item-box {
  width: 85%;
  border-radius: 30px;
  background-color: #A52228;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0px;
}

.alliance-item-box P {
  font-size: 24px;
  color: #DFBE81;
  margin: 0px;
}

.alliance-item-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 80%;
  margin-top: 15px;
}

.a-item {
  width: 15%;
}

.supervise-container {
  width: 85%;
  margin: 0px auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 0px 60px 0px;
}

.item-supervise,
.item-report {
  width: 49%;
  background-color: #A52228;
  border-radius: 30px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.item-supervise p,
.item-report p {
  font-size: 24px;
  color: #DFBE81;
  margin: 0px;
}

</style>
