<template>
	<main class="page-wrapper">
		<Header :initData="companyInfo" v-if="isShow" />

		<div class="main-content">
			<slot></slot>
		</div>

		<Footer :initData="companyInfo" v-if="isShow" />
	</main>
</template>

<script>
import Header from "@/components/common/header/Header";
import Footer from "@/components/common/footer/Footer";

import { getCompany } from '@/api/backstage/company_news';

export default {
	name: "Layout",
	components: { Header, Footer },
	data() {
		return {
			companyInfo: {
				companyName: '',
				companyAddress: '',
				hotLine: '',
				website: '',
				recordNumber: '',
			},
			compantList: [],
			isShow: false,
		};
	},

	created() {
		this.getCompanyInfo();
	},

	methods: {
		getCompanyInfo() {
			getCompany().then(response => {
				this.compantList = response.data;
				if (this.compantList) {
					this.companyInfo.companyName = this.compantList[0].companyName;
					this.companyInfo.companyAddress = this.compantList[0].companyAddress;
					this.companyInfo.hotLine = this.compantList[0].companyPhone;
					this.companyInfo.website = this.compantList[0].webSite;
					this.companyInfo.recordNumber = this.compantList[0].recordNumber;
					this.isShow = true;
				}
			}).catch(error => {
				this.$message({
					showClose: true,
					message: '网络延时，请刷新重试',
					type: 'warning',
					customClass: 'mzindex'
				});
			});
		},
	},
}
</script>

<style scoped>
.main-content {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
}
</style>
