import request from '@/utils/request'

/** 获取公司信息 */
export function getCompany() {
    return request ({
        url: '/api/details/listCompanyDetails',
        method: 'get'
    })
}

/** 获取公告/动态等类型的信息 */
export function getNewsList(query) {
    return request({
        url: '/api/center/list',
        method: 'get',
        params: query
    })
}

/** 获取详细信息 */
export function getNews(id) {
    return request({
        url: '/api/center/' + id,
        method: 'get'
    })
}

/** 获取视频 */
export function getVideo(query) {
    return request({
        url: '/cdmf/app/file/videoList',
        method: 'get',
        params: query
    })
}

// 查询凭证文件资源列表
export function voucherlist(query) {
    return request({
        url: '/cdmf/app/file/voucherlist',
        method: 'get',
        params: query
    })
}