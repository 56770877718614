<template>
  <Layout>
    <div class='section' style='padding:10px'>
      <div class='container'>
        <div class='section--header'>

          <div class='top-title'>
            <span class='section--title'>合作直通车</span><br />
            <span>COOPERATIVE DIRECT TRAIN</span>
            <p class="title-sch">产品研发</p>
          </div>
        </div>
        <div class='advantage-container'>
          <template v-for="(item,key) in dataList">
            <div :style="{width:item.width?item.width + '%':'100%'}">
              <div class="rice-culture-container">
                <p class="rice-culture-title">{{item.newsTitle}}</p>
              </div>
              <div v-if="item.newsMainBody" class="rice-item-right-text" v-html="item.newsMainBody">
              </div>
              <img v-if="item.cover" :src="imgUrl + item.cover" alt='loading...' class="img_fluid">
            </div>
          </template>
        </div>

<!--        &lt;!&ndash; 图片banner层 &ndash;&gt;-->
<!--        <div class='banner-container'>-->
<!--          <img src="../assets/images/cdmf-img/culture-img/chanyebanner.png" class="img_fluid">-->
<!--        </div>-->

<!--        &lt;!&ndash; 产业园层 &ndash;&gt;-->
<!--        <div class="park-container">-->
<!--          <p class="park-title">研发实力介绍：产业园</p>-->

<!--          <div class="park-box">-->
<!--            <div class="park-box-left">-->
<!--              <p>园区选址：<small>常德市经济开发区</small></p>-->
<!--              <p>园区面积：<small>园区总占地面积6万平方米,总建筑占地面积3.6万余平方米,总建筑面积5万余平方米。</small></p>-->
<!--              <p>园区布局：<small>生产车间3栋:包含冷藏库；产业大楼1栋:含常德米粉博物馆、常德米粉信息中心、常德米粉文创展示中心、常德米粉交易中心、常德米粉VR产业展示中心、常德米粉体验中心；培训中心1栋；含一楼培训中心,二楼会议中心；后勤大楼1栋:含一楼食堂商店,二楼活动中心:污水处理中心及配电站各1座,包含污水处理,配电房、垃圾站。</small></p>-->
<!--            </div>-->
<!--            <div class="park-box-right">-->
<!--              <p class="park-box-right-title">技术合作招商要求</p>-->
<!--              <p class="park-box-right-phone">对接电话：400-000 0000</p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--        &lt;!&ndash; 生产供应、厂家加盟层 &ndash;&gt;-->
<!--        <div class="industrial-alliance-container">-->
<!--          <a href="" class="supply">-->
<!--            <p class="industrial-alliance-title">生产供应</p>-->
<!--            <img src="../assets/images/cdmf-img/cooperation-img/gongying.png" class="img_fluid">-->
<!--				  </a>-->

<!--          <a href="" class="join">-->
<!--            <p class="industrial-alliance-title">厂家加盟</p>-->
<!--            <img src="../assets/images/cdmf-img/cooperation-img/jiameng.png" class="img_fluid">-->
<!--          </a>-->
<!--        </div>-->

<!--        &lt;!&ndash; 产业物流、厂家加盟层 &ndash;&gt;-->
<!--        <div class="industrial-alliance-container">-->
<!--          <a href="" class="supply">-->
<!--            <p class="industrial-alliance-title">产业物流</p>-->
<!--            <img src="../assets/images/cdmf-img/cooperation-img/car.png" class="img_fluid">-->
<!--				  </a>-->

<!--          <div href="" class="join">-->
<!--            <p class="industrial-alliance-title">厂家加盟</p>-->
<!--            <div class="join-box">生产商合作标准</div>-->
<!--            <div class="join-box">现有招商网店查询（按钮）</div>-->
<!--            <div class="join-box">对接电话</div>-->
<!--          </div>-->

<!--        </div>-->

<!--        &lt;!&ndash; 物流技术层 &ndash;&gt;-->
<!--        <div class="technology-container">-->
<!--          <p class="technology-title">物流技术简介：</p>-->
<!--          <p class="technology-text">为更好的提供全国冷链物流配送能力，集团成立常德中蔬物流服务有限公司，实现常德米粉流通领域整合，实施全程冷链管控配送。目前已投入冷链配送车辆100余台，初步行程安全、专业、高效的物流配送体系，确保米粉运营安全可控。</p>-->
<!--          <p class="technology-title">物流服务宗旨：</p>-->
<!--          <p class="technology-text">服务宗旨：热情、耐心、周到、细致，想顾客所想，急顾客所急。充分体现常德米粉“至真至诚”的服务理念。</p>-->
<!--        </div>-->

<!--        &lt;!&ndash; 信息化技术层 &ndash;&gt;-->
<!--        <div class="information-container">-->
<!--          <p class="industrial-alliance-title">信息化服务</p>-->

<!--          <div class="information-box">-->
<!--            <div class="information-box-left">-->
<!--              <p class="information-box-left-text">信息化技术支持简介：<br>为保障老百姓对常德米粉健康安全的需求，集团成立常德米粉信息技术公司，为实施产品监管和交易的平台化。并自主开发智慧物流APP系统，实现运输管理可控、交易信息采集、车辆安全预警、产品质量溯源等功能。</p>-->
<!--              <p class="information-box-left-time">联系客服：400-000-0000</p>-->
<!--            </div>-->
<!--            <div class="information-box-right">-->
<!--              <img src="../assets/images/cdmf-img/cooperation-img/xinxihua.png" class="img_fluid">-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--        &lt;!&ndash; 创业层 &ndash;&gt;-->
<!--        <div class="industrial-alliance-container">-->
<!--          <a href="" class="supply">-->
<!--            <p class="industrial-alliance-title">创业扶持</p>-->
<!--            <img src="../assets/images/cdmf-img/cooperation-img/fuchi1.png" class="img_fluid">-->
<!--				  </a>-->

<!--          <div href="" class="join">-->
<!--            <p class="industrial-alliance-title">创业扶持</p>-->
<!--            <img src="../assets/images/cdmf-img/cooperation-img/fuchi2.png" class="img_fluid">-->
<!--          </div>-->

<!--        </div>-->

<!--        &lt;!&ndash; 创业扶持简介层 &ndash;&gt;-->
<!--        <div class="introduction-container">-->
<!--          <p class="industrial-alliance-title">创业扶持简介：</p>-->
<!--          <p class="introduction-title">1、选址支持：</p>-->
<!--          <p class="introduction-text">总部安排选址专员电话和网络指导，协助经销店店面选址及店铺装修测量。</p>-->
<!--          <p class="introduction-title">2、装修支持：</p>-->
<!--          <p class="introduction-text">根据加盟商平面尺寸图为店面提供全店形象设计、装修图纸,并提供装修一站式全包服务。</p>-->
<!--          <p class="introduction-title">3、产品支持：</p>-->
<!--          <p class="introduction-text">每天通过遍布全国的冷链物流系统将加盟门店下单产品配送至各店面。</p>-->
<!--          <p class="introduction-title">4、开业支持：</p>-->
<!--          <p class="introduction-text">量身制定开业促销方案,并在开业时委派资深营销人员上门指导。</p>-->
<!--          <p class="introduction-title">5、运营支持：</p>-->
<!--          <p class="introduction-text">总部不定期派遣店面运营督导亲临门店现场提供业务指导。</p>-->
<!--          <p class="introduction-title">6、广告支持：</p>-->
<!--          <p class="introduction-text">总部将在电视、网络、移动新媒体等多平台进行品牌推广,促进品牌在全国市场的快速发展。</p>-->
<!--          <p class="introduction-title">7、培训支持：</p>-->
<!--          <p class="introduction-text">总部将从产品制作、店面管理等全方位对加盟商人员进行免费培训,确保通过公司考核持证上岗。</p>-->
<!--          <p class="introduction-title">8、带店支持：</p>-->
<!--          <p class="introduction-text">根据加盟店需要委派资深店面管理人员协助加盟商店面开业。</p>-->
<!--          <p class="introduction-title">9、线上支持：</p>-->
<!--          <p class="introduction-text">微信支付宝平台总部直接对接,开通线上支付,并为加盟店提供独有的线上市场活动。</p>-->
<!--          <p class="introduction-title">10、外卖支持：</p>-->
<!--          <p class="introduction-text">总部市场部门为各门店统一对接专业外卖平台,并提供统一的后台服务。</p>-->
<!--        </div>-->

<!--        &lt;!&ndash; 扶持申请通道层 &ndash;&gt;-->
<!--        <div class="channel-container">-->
<!--          <a href="" class="channel">扶持申请通道</a>-->
<!--        </div>-->

      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/components/common/Layout';
import {getNewsList} from "@/api/backstage/company_news";
export default {
  name: 'AboutView',
  components: { Layout },
  data() {
    return {
      dataList:[],
      imgUrl: process.env.VUE_APP_FILE_SERVER,
    };
  },

  created() {
    this.getTopPicture();
  },

  methods: {

    getTopPicture(){
      let param = { newsType: 8,newsState: 1};
      getNewsList(param).then(resp => {
        this.dataList = [];
        if (resp.rows && resp.rows.length > 0) {
          this.dataList = resp.rows;
        }
      }).catch(error => {
        this.$message({
          showClose: true,
          message: '网络延时，请刷新重试',
          type: 'warning',
          customClass: 'mzindex'
        });
      });
    },
  },
};
</script>

<style scoped>
.section {
  padding-bottom: 60px;
}

.top-title {
  text-align: center;
}

.top-title span {
  font-size: 12px;
  font-weight: 500;
}

.top-title .title-sch{
  margin-top: 15px;
  font-size: 24px;
  color: #A52228;
  text-align: center;
  font-weight: 600;
}

.img_fluid {
  width: 100%;
  height: auto;
}

.banner-container {
  width: 100%;
}

/* 产业园样式 */
.park-container {
  width: 90%;
  margin: 0px auto;
  padding: 60px 0px;
}

.park-title {
  font-size: 24px;
  color: #A52228;
  font-weight: bold;
  margin: 0px 0px 10px 0px;
}

.park-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.park-box-left {
  width: 59%;
  padding: 30px;
  background-color: #A52228;
}

.park-box-left p {
  text-align: 18px;
  color: #fff;
  text-align: left;
  margin: 0px auto;
  font-weight: bold;
}

.park-box-right {
  width: 39%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #A52228;
}

.park-box-right-title {
  font-size: 32px;
  margin: 0px;
  color: #DFBE81;
}

.park-box-right-phone {
  font-size: 21px;
  margin: 0px;
  color: #DFBE81;
}

/* 产业联盟模块样式 */
.industrial-alliance-container {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px auto;
  padding: 60px 0px;
}

.supply, .join {
  width: 49%;
  text-decoration: none !important;
}

.industrial-alliance-title {
  font-size: 24px;
  margin-bottom: 10px;
  color: #A52228;
  font-weight: bold;  
}

.join-box {
  background-color: #A52228;
  color: #DFBE81;
  font-size: 24px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

/* 物流技术样式 */
.technology-container {
  width: 90%;
  margin: 0px auto;
  padding: 0px 0px;
}

.technology-title {
  font-size: 21px;
  font-weight: bold;
  color: #000;
  margin: 0px;
}

.technology-text {
  font-size: 18px;
  /* font-weight: bold; */
  color: #000;
  margin: 0px;
}

/* 信息化样式 */
.information-container {
  width: 90%;
  margin: 0px auto;
  padding: 60px 0;
}

.information-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.information-box-left {
  width: 60%;
  padding: 30px;
  background-color: #A52228;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.information-box-right {
  width: 39%;
}

.information-box-left-text {
  font-size: 32px;
  color: #fff;
  margin: 0px;
  text-align: left;
}

.information-box-left-time {
  font-size: 32px;
  color: #fff;
  margin: 0px;
  text-align: left;
}

/* 创业扶持简介层 */
.introduction-container {
  width: 90%;
  margin: 0px auto;
  padding: 0px 0px 60px 0px;
}

.introduction-title {
  font-size: 21px;
  color: #000;
  font-weight: bold;
  text-align: left;
  margin: 0px;
}

.introduction-text {
  font-size: 18px;
  color: #000;
  text-align: left;
}

.channel-container {
  width: 100%;
  /* padding: 0px 0px 60px 0px; */
  padding: 0px 0px 0px 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.channel {
  width: 30%;
  padding: 30px;
  border-radius: 30px;
  background-color: #A52228;
  color: #DFBE81 !important;
  text-align: center;
  font-size: 32px;
  text-decoration: none !important;
}

/* 稻田文化层样式 */
.rice-culture-container {
  width: 95%;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 0px 0px 0px;
}

.rice-culture-title {
  font-size: 24px;
  color: #A52228;
  font-weight: 600;
}
</style>
