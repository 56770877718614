<template>
  <Layout>
    <div class='section' style='padding-top: 20px;'>
      <div class='container'>

        <div class='section--header'>
          <div class='top-title'>
            <span class='section--title'>产业概况</span><br />
            <span>INDUSTRY OVERVIEW</span>
          </div>
        </div>
        <template v-for="(item,key) in dataList">
          <div :style="{width:item.width?item.width + '%':'100%'}">
            <div class="rice-culture-container">
              <p class="rice-culture-title">{{item.newsTitle}}</p>
            </div>
            <img v-if="item.cover" :src="imgUrl + item.cover" alt='loading...' class="img_fluid">
            <div v-if="item.newsMainBody"
                 style="width:90%;margin:auto;text-indent: 2em;"
                 class="rice-item-right-text" v-html="item.newsMainBody">
            </div>
          </div>
        </template>

        <!-- 图片banner层 -->
<!--        <div class='banner-container'>-->
<!--          <img src="../assets/images/cdmf-img/culture-img/chanyebanner.png" class="img_fluid">-->
<!--        </div>-->

<!--&lt;!&ndash;        &lt;!&ndash; 米粉简述层 &ndash;&gt;&ndash;&gt;-->
<!--        <div class='sketch-container'>-->
<!--          <p class="sketch-text">-->
<!--            常德米粉产业集团致力于供应商资源整合，现已整合18家常德米粉本地米粉生产厂家，由分散的个人向高效配送服务团队转型；平台自主研发了智慧物流监控系统，实施全程 24-->
<!--            小时运输管理与监控；建立区域中央厨房，服务所在城市连锁门店商家；实施全程冷链配送，不仅保证了米粉运送的新鲜，同时也确保了食品的安全性；并拥有完整的产业全媒体宣传矩阵，进行品牌标准制定、品牌形象孵化、传播渠道整合、市场终端推广、电商品台运营、连锁模式打造等，为“打造百亿产业，实施千城万店”的目标提供有力保障。-->
<!--				  </p>-->
<!--          <p class="sketch-title"> 常德米粉简述 </p>-->
<!--          <p class="sketch-text">-->
<!--            常德米粉是湖南省常德市的一道传统风味小吃,属于湘菜系。-->
<!--            米粉历史悠久,早在清光绪年间,常德就有了生产米粉的店坊,生产的米粉又细又长。长期以来,常德人不论男女老幼都喜欢食用米粉。-->
<!--            常德米粉的主要原料是大米,早籼米经过十几小时水浸,打浆,加热定型特殊工艺制成洁白、混圆、细长且有弹性的粉条,只要用开水烫热,加上作料后即可食用,吃起来润滑可口、风味独具特色。-->
<!--				  </p>-->

<!--        </div>-->

<!--&lt;!&ndash;        &lt;!&ndash; 特色层 &ndash;&gt;&ndash;&gt;-->
<!--        <div class="feature-container">-->
<!--          <img src="../assets/images/cdmf-img/culture-img/mifen.png" alt="loading...">-->
<!--          <div class="feature-right">-->
<!--            <p class="feature-right-title">米粉特色介绍</p>-->
<!--            <p class="feature-right-text">臻选洞庭湖早籼米</p>-->
<!--            <p class="feature-right-text">本地优质沅江水酵制</p>-->
<!--            <p class="feature-right-text">常德特有鲜粉发酵工艺</p>-->
<!--            <p class="feature-right-text">13道传统工艺</p>-->
<!--            <p class="feature-right-text">米香纯正、柔韧断条少、蒸煮不糊汤</p>-->
<!--          </div>-->
<!--        </div>-->

<!--        &lt;!&ndash; 稻田文化层 &ndash;&gt;-->
<!--        <div class="rice-culture-container">-->
<!--          <p class="rice-culture-title">源自城头山的7千年稻田文化</p>-->
<!--          <div class="rice-culture-item">-->
<!--            <div class="rice-item-img">-->
<!--              <img src="../assets/images/cdmf-img/culture-img/daotian.png">-->
<!--            </div>-->
<!--            <div class="rice-item-right">-->
<!--              <p class="rice-item-right-text">-->
<!--							  城头山遗址位于澄阳平原,该平原是我国史前遇址最密集的区域之一,-->
<!--                距离城头山遗址1公里处,发现距今约8000年的人工栽培程;-->
<!--                距离城头山选址10多公里处,则发现了距令约8000年的大量稻田实物标本,-->
<!--                其中40%有人工栽培痕迹,有水坑和水沟等原始灌溉系统,是现存灌溉设施完备的世界最早的水稻田。-->
<!--                1992年、1997年,城头山遗址先后两次被评为年度“中国十大考古新发现"之一:1996年被评为"全国重点文物保护单位",-->
<!--                2001年入选“中国20世纪100项考古大发现”。-->
<!--						  </p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

<!--&lt;!&ndash;        &lt;!&ndash; 步骤层 &ndash;&gt;&ndash;&gt;-->
<!--        <div class="procedure-container">-->
<!--          <p class="procedure-title">常德米粉的美味秘密</p>-->
<!--          <div class="procedure-img-list">-->
<!--            <img src="../assets/images/cdmf-img/culture-img/chuansongdai.png">-->
<!--            <img src="../assets/images/cdmf-img/culture-img/buzhou.png">-->
<!--            <img src="../assets/images/cdmf-img/culture-img/liushui.png">-->
<!--          </div>-->
<!--        </div>-->

<!--        &lt;!&ndash; 民间传说层 &ndash;&gt;-->
<!--        <div class="legend-container">-->
<!--          <p class="legend-title">田间奇缘 美好传说</p>-->
<!--          <img src="../assets/images/cdmf-img/culture-img/kanqiao.png" class="img_fluid">-->

<!--          <div class="legend-story-box">-->
<!--            <p class="legend-story-text">刘海砍樵故事</p>-->
<!--            <p class="legend-story-text">因为一份爱,《刘海砍樵》故事得到延续;因为一份情,胡大姐又在常德上演了一曲米粉传奇。</p>-->
<!--            <p class="legend-story-text">话说两人婚后,胡大姐对刘海的感情愈加浓厚,家中丝瓜井的井水也越来越甘甜。</p>-->
<!--            <p class="legend-story-text">刘海以砍柴为生,每次上山,肚子一饿就是大半天,她心疼的不得了,不停地问自己:弄什么好吃的给他充饥呢?</p>-->
<!--            <p class="legend-story-text">忽然有一天,一轮明月升起,胡大姐甜蜜进入梦乡。她用丝瓜井井水,把大米在水桶、石磨中倒腾,连续七道工序后,一道灵光闪现,一盆白净、丝滑的米粉呼之而出,好香啊!刘海哥一口气吃了三大碗,连声赞叹:好爽!真爽!</p>-->
<!--            <p class="legend-story-text">一觉醒来,依梦成真,夫妻两人大喜,在丝瓜井旁开起了“胡大姐米粉店”,天长日久,生意兴降。</p>-->
<!--            <p class="legend-story-text">真可谓:刘海哥砍樵砍得肚肚饥,胡大姐捣粉捣出嘴嘴爽！</p>-->
<!--            <p class="legend-story-text">千年传承,米粉飘香。只有用常德的水、米和独特工艺,才能做出常德米粉。它是常德人民的一种永久怀念和乡情依恋,更是一种文化存在。</p>-->
<!--            <p class="legend-story-text">常德米粉,国家地理标志证明商标。嗦的有味,吃的潇洒!</p>-->
<!--          </div>-->
<!--        </div>-->

      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/components/common/Layout';
import {getNewsList} from "@/api/backstage/company_news";
export default {
  name: 'CultureView',
  components: { Layout },
  data() {
    return {
      dataList:[],
      imgUrl: process.env.VUE_APP_FILE_SERVER,
    };
  },

  created() {
    this.getTopPicture();
  },

  methods: {

    getTopPicture(){
      let param = { newsType: 7,newsState: 1};
      getNewsList(param).then(resp => {
        this.dataList = [];
        if (resp.rows && resp.rows.length > 0) {
          this.dataList = resp.rows;
        }
      }).catch(error => {
        this.$message({
          showClose: true,
          message: '网络延时，请刷新重试',
          type: 'warning',
          customClass: 'mzindex'
        });
      });
    },
  },
};
</script>

<style scoped>
.top-title {
  text-align: center;
}

.top-title span {
  font-size: 12px;
  font-weight: 500;
}

.top-title .section--title {
  color: #A52228;
  font-family: -apple-system,BlinkMacSystemFont;
}

.img_fluid {
  width: 100%;
  height: auto;
}

/* banner层样式 */
.banner-container {
  width: 100%;
}

/* 米粉简述层样式 */
.sketch-container {
  width: 90%;
  margin: 0px auto;
  padding: 60px 0;
}

.sketch-text {
  font-size: 18px;
  font-weight: 600;
  color: #000;
  text-indent: 2em;
}

.sketch-title {
  font-size: 24px;
  font-weight: 600;
  color: #A52228;
  text-indent: 2em;
  text-align: center;
  margin: 30px 0;
}

/* 特殊层样式 */
.feature-container {
  width: 95%;
  margin: 0px auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 0px 60px 0px;
}

.feature-container img {
  width: 48%;
  height: auto;
}

.feature-container .feature-right {
  width: 48%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #A52228;
}

.feature-right-title {
  color: #DFBE81;
  font-weight: 600;
  font-size: 36px;
}

.feature-right-text {
  color: #DFBE81;
  font-size: 28px;
  font-weight: 500;
}

p {
  margin-top: 0;
  margin-bottom: 1.1rem;
}

/* 稻田文化层样式 */
.rice-culture-container {
  width: 95%;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 0px 60px 0px;
}

.rice-culture-title {
  font-size: 24px;
  color: #A52228;
  text-align: center;
  font-weight: 600;
}

.rice-culture-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 30px 0px;
}

.rice-item-img {
  width: 48%;  
  height: auto;
}

.rice-culture-item img {
  width: 100%;
  height: 100%;
}

.rice-item-right {
  width: 48%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #A52228;
}

.rice-item-right-text {
  /*font-size: 28px;*/
  /*color: #DFBE81;*/
  /*text-indent: 2em;*/
  text-align: left;
  color: #000;
  font-weight: 600;
  font-size: 21px;
}

/* 步骤层样式 */
.procedure-container {
  padding: 0px 0px 60px 0px;
  width: 95%;
  margin: 0px auto;
}

.procedure-title {
  font-size: 24px;
  color: #A52228;
  text-align: center;
  font-weight: 600;
}

.procedure-img-list {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.procedure-img-list img {
  width: 31%;
  height: auto;
}

/* 添加传说样式层 */
.legend-container {
  width: 100%;
  /* padding: 0px 0px 60px 0px; */
}

.legend-title {
  font-size: 24px;
  color: #A52228;
  text-align: center;
  font-weight: 600;
}

.legend-story-box {
  width: 80%;
  margin: 0px auto;
  padding: 60px 0px 0px 0px;
}

.legend-story-text {
  text-align: left;
  color: #000;
  font-weight: 600;
  font-size: 21px;
}
</style>
