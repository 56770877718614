<template>
	<div class="footer">
		<div style="/*background-color:#383d61*/" class="footer--bg"></div>
		<div class="footer--inner">
			<div class="am-g">
				<div class="am-u-md-3" style="width: 50%; margin-left: -6%;">
					<div class="footer_main--column">
						<strong class="footer_main--column_title">{{ this.companyName }}</strong>
						<ul class="footer_navigation">
							<li class="footer_contact_info--item"><i class="am-icon-map-marker"></i><span>公司总部：{{
								this.companyAddress }}</span></li>
							<li class="footer_contact_info--item"><i class="am-icon-phone"></i><span>服务热线：{{
								this.hotLine }}</span></li>
							<li class="footer_contact_info--item"><i class="am-icon-map-marker"></i><span>官方网址：{{
								this.website
							}}</span></li>
							<li class="footer_contact_info--item"><i class="am-icon-clock-o"></i><span>Copyright &copy; 2021
									Changdemifen.com All Rights Reserved. {{ this.companyName }} 版权所有 </span></li>
							<li class="footer_contact_info--item"><a href="#">{{ this.recordNumber }}</a></li>
						</ul>
					</div>
				</div>

				<div class="am-u-md-3" style="width: 40%; margin-right: -6%; text-align: center;">
					<div class="footer_main--column">
						<div class="code-img"><img :src="codeImg" alt="loading..."></div>

						<strong class="footer_main--column_title">常德米粉产业微信公众号</strong>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>

<script>
import codeImg from "@/assets/images/cdmf-img/about-img/footqcode.png";

export default {
	name: "Footer",
	props: {
		initData: {
			type: Object
		}
	},
	data() {
		return {
			companyName: '',
			companyAddress: '',
			hotLine: '',
			website: '',
			recordNumber: '',
			codeImg: codeImg,
		};
	},

	watch: {
	},

	mounted() {
		if (this.initData) {
			this.companyName = this.initData.companyName;
			this.companyAddress = this.initData.companyAddress;
			this.hotLine = this.initData.hotLine;
			this.website = this.initData.website;
			this.recordNumber = this.initData.recordNumber;
		}
	},

	create() {
	},

	methods: {

	},
}
</script>

<style scoped>
.code-img img {
	height: 250px;
	width: 250px;
}

/* 常德米粉logo */
.footer {
	width: 100%;
	background: url('../../../assets/images/cdmf-img/about-img/footbackground.png') no-repeat center center;
	background-size: cover;
	position: relative
}
</style>
