<template>
  <Layout>
    <div class='section' style='padding-top: 20px;'>
      <div class='container'>
        <div class='section--header'>
          <div class='top-title'>
            <span class='section--title'>产业优势</span><br />
            <span>INDUSTRIAL ADVANTAGE</span>
          </div>
        </div>

        <!-- 产业优势层 -->
        <div class='index-container'>
          <template v-for="(item,key) in dataList">

            <div :style="{width:item.width?item.width + '%':'100%'}">
              <div class='top-title'>
                <span class='section--title' style="font-size:14px" v-if="item.newsTitle">{{item.newsTitle}}</span><br />
                <span  v-if="item.newsSubheading">{{item.newsSubheading}}</span>
                <div v-if="item.newsMainBody" class="rice-item-right-text" v-html="item.newsMainBody">
                </div>
              </div>

              <img :src="imgUrl + item.cover" alt='loading...' class="img_fluid">
            </div>
          </template>
        </div>

      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/components/common/Layout';
import {getNewsList} from "@/api/backstage/company_news";
export default {
  name: 'AboutView',
  components: { Layout },
  data() {
    return {
      dataList:[],
      imgUrl: process.env.VUE_APP_FILE_SERVER,
    };
  },

  mounted() {
    this.getTopPicture();
  },

  methods: {

    getTopPicture(){
      let param = { newsType: 6,newsState: 1};
      getNewsList(param).then(resp => {
        this.dataList = [];
        if (resp.rows && resp.rows.length > 0) {
          this.dataList = resp.rows;
        }
      }).catch(error => {
        this.$message({
          showClose: true,
          message: '网络延时，请刷新重试',
          type: 'warning',
          customClass: 'mzindex'
        });
      });
    },
  },
};
</script>

<style scoped>
.top-title {
  text-align: center;
}

.top-title span {
  font-size: 12px;
  font-weight: 500;
}

.top-title .section--title {
  color: #A52228;
  font-family: -apple-system,BlinkMacSystemFont;
}

.img_fluid {
  width: 100%;
  height: auto;
}

/* 产业优势层样式 */
.advantage-container {
  width: 100%;
}

.advantage-item-title {
  color: #A52228;
  font-size: 21px;
  text-align: center;
  font-weight: 600;
  text-align: center;
}

.advantage-item-text {
  font-size: 18px;
  text-align: center;
  font-weight: 600;
  text-align: center;
}

.advantage-container img {
  margin-bottom: 60px;
}

.advantage-container img:last-child {
  margin-bottom: 0;
}
</style>
