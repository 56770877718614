<template>
    <Layout>
        <div class="section">
            <div class="container">
                <div class="solution-container">
                    <div style="text-align: center; padding-bottom: 20px;">
                        <h1>{{ defaultShow.newsTitle ? defaultShow.newsTitle : '' }}</h1>
                        <div style="text-align: center;">
                            <span style="font-size: 16px; font-weight: 600; padding-right: 10px;">
                                {{ defaultShow.newsSubheading ? defaultShow.newsSubheading : '' }}
                            </span>
                            <span v-if="defaultShow.registrationTime">[发布日期：{{ defaultShow.registrationTime ?
                                timestampToTime(defaultShow.registrationTime) : '' }}]</span>
                        </div>
                    </div>
                    <div style="width: 798px; text-align: left;" v-html="defaultShow.newsMainBody"></div>
                </div>
            </div>
        </div>
    </Layout>
</template>
  
<script>
import Layout from "@/components/common/Layout";
import { parseTime } from '@/utils/common';
import { getNews } from '@/api/backstage/company_news';

export default {
    name: "newsDetail",
    components: { Layout },
    data() {
        return {
            queryParams: {
                newsId: null,
            },

            defaultShow: {
                newsTitle: '',
                newsSubheading: '',
                registrationTime: null,
                newsMainBody: '',
            }
        };
    },

    created() {
        this.init();
        this.getNewsInfo();
    },

    methods: {
        // 初始化调用
        init () {
            this.queryParams.newsId = this.$route.query.id;
        },

        getNewsInfo () {
            getNews(this.queryParams.newsId).then(resp => {
                this.defaultShow = resp.data;
            }).catch(() => {
                this.$message({
                    showClose: true,
                    message: '网络延时，请刷新重试',
                    type: 'warning',
                    customClass: 'mzindex'
                });
            });
        },

        /* 时间戳转换为时间 */
        timestampToTime(timestamp) {
            return parseTime(timestamp, '{y}/{m}/{d}');
        },
    },

};
</script>
  
<style scoped>
.section {
    padding: 10px 0;
}

</style>
  