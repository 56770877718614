import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path:'/',
        redirect:'/index'
    },
    {
        path: '/showMaterInfo',
        component: () => import('@/views/showInfo'),
        hidden: true
    },
    {   // 首页
        path: '/index',
        name: 'index',
        component: () => import('../views/IndexView.vue')
    },
    {   // 关于我们
        path: '/about',
        name: 'about',
        component: () => import('../views/AboutView.vue')
    },
    {   // 集团业务
        path: '/business',
        name: 'business',
        component: () => import('../views/BusinessView.vue')
    },
    {   // 米粉文化
        path: '/culture',
        name: 'culture',
        component: () => import('../views/CultureView.vue')
    },
    {   // 招商合作
        path: '/cooperation',
        name: 'cooperation',
        component: () => import('../views/CooperationView.vue')
    },
    {   // 招投标
        path: '/tenders',
        name: 'tenders',
        component: () => import('../views/tenderView.vue')
    },
    {   // 招标项目详情
        path: '/projectDetail',
        name: 'projectDetail',
        component: () => import('../views/projectDetailView.vue')
    },
    {   // 中标项目详情
        path: '/publicityDetail',
        name: 'publicityDetail',
        component: () => import('../views/publicityDetail.vue')
    },
    {   // 新闻中心
        path: '/news',
        name: 'news',
        component: () => import('../views/NewsView.vue')
    },
    {
        path: '/news/newsId/:newsId',
        name: 'newsDetails',
        component: () => import('../views/NewsDetailsView.vue')
    },
    {   // 联系我们
        path: '/us',
        name: 'us',
        component: () => import('../views/UsView.vue')
    },
    {   // 新闻更多
        path: '/newsMore',
        name: 'newsMore',
        component: () => import('../views/newsMore.vue')
    },
    {   // 新闻详情
        path: '/newsDetail',
        name: 'newsDetail',
        component: () => import('../views/newsDetail.vue')
    },

]

const router = new VueRouter({
    routes
})

export default router
