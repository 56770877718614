import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/style.css'
import {getRequest} from "@/api/api";
// 引入element
import ElementUI  from 'element-ui';        // 2.1引入结构
import 'element-ui/lib/theme-chalk/index.css'; // 2.2引入样式
// 全局引入element的富文本编辑器
// import quillEditor from "vue-quill-editor";
// import "quill/dist/quill.core.css";
// import "quill/dist/quill.snow.css";
// import "quill/dist/quill.bubble.css";
//这个是插件优化的样式，在下面贴出来
// 分页组件
import Pagination from "@/components/Pagination";
// 挂载到$message上


// 全局挂载组件
Vue.component('pagination', Pagination);

// 3.安装
// Vue.use(quillEditor)
Vue.use(ElementUI);
Vue.prototype.getRequest = getRequest;


Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
