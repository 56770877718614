<template>
  <Layout>
    <div class="layout-container" style="width: 100%">
      <div class="solution-page">
        <div class="container" style="text-align: center">
          <h2>中标公示详情</h2>
        </div>
      </div>
    </div>
    <div class="layout-container" style="width: 100%">
      <div class="breadcrumb-box">
        <div class="am-container">
          <ol class="am-breadcrumb">
            <li><router-link to="/">首页</router-link></li>
            <li class="am-active">中标公示详情</li>
          </ol>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="container" style="max-width: 1160px">
        <div class="section--header">
          <!-- <h2 class="section--title">招标公告展示</h2>
          <p class="section--description"></p> -->
        </div>

        <div class="solution-container">
          <el-card class="box-card">
            <!-- 招标项目详情展示 -->
            <el-divider content-position="left">中标公示详情</el-divider>
            <el-form ref="publicity" :model="publicity" label-width="140px" disabled>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="项目名称：" prop="projectName">
                            {{publicity.projectName}}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">

                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="开标时间：" prop="kaibiaoTime">
                            {{publicity.kaibiaoTime}}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="定标时间：" prop="dingbiaoTime">
                            {{publicity.dingbiaoTime}}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="中标公示发布时间：" prop="zbgsbeginTime">
                            {{publicity.zbgsbeginTime}}
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="中标公示截止时间：" prop="zbgsendTime">
                            {{publicity.zbgsendTime}}
                        </el-form-item>
                    </el-col>
                </el-row>
                
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="附件：" prop="zbgsAttach">
                            <template slot-scope="scope">
                            <el-button :disabled="disabled"
                                size="mini"
                                type="primary"
                                icon="el-icon-download"
                                @click="downloadFile(scope.row)">
                              下载附件
                            </el-button>
                            </template>
                        </el-form-item>
                    </el-col>
                </el-row>


                <el-row>
                    <el-col :span="24">
                        <el-form-item label="招标内容：">
                            <div style="overflow:auto;height: 350px;" v-html="publicity.content"></div>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>


          </el-card>
        </div>
      </div>
    </div>
  </Layout>

</template>

<script>
import Layout from "@/components/common/Layout.vue";
import { getPublicity, addMessage, addAttach } from "@/api/render/project";
import { htmlDecode, getFormatterDate } from "@/utils/common/comm-utils";
// import { Message } from 'element-ui';
// Message.install = function(Vue, options) {
//   Vue.prototype.$message = Message;
// }
// Vue.use(Message);
// 局部引入富文本框
// 引入样式和quillEditor
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

// 工具栏配置项
const toolbarOptions = [
  // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
  ["bold", "italic", "underline", "strike"],
  // 引用  代码块-----['blockquote', 'code-block']
  ["blockquote", "code-block"],
  // 1、2 级标题-----[{ header: 1 }, { header: 2 }]
  [{ header: 1 }, { header: 2 }],
  // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
  [{ list: "ordered" }, { list: "bullet" }],
  // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
  [{ script: "sub" }, { script: "super" }],
  // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
  [{ indent: "-1" }, { indent: "+1" }],
  // 文本方向-----[{'direction': 'rtl'}]
  [{ direction: "rtl" }],
  // 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
  [{ size: ["small", false, "large", "huge"] }],
  // 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
  [{ color: [] }, { background: [] }],
  // 字体种类-----[{ font: [] }]
  [{ font: [] }],
  // 对齐方式-----[{ align: [] }]
  [{ align: [] }],
  // 清除文本格式-----['clean']
  ["clean"],
  // 链接、图片、视频-----['link', 'image', 'video']
  ["image", "video"],
];

export default {
  name: "publicityDetail",
  components: {
    Layout,
    quillEditor,
  },
  hidden: {
      type: Boolean,
      default: false
    },
  data() {
    return {
      // 【请填写功能名称】表格数据
      // 查询参数
      // 表单参数
      form: {},
      publicity: {},
      disabled: false,
      // 富文本
      editorOption: {
        modules: {
          toolbar: toolbarOptions,
        },
        theme: "snow",
        placeholder: "请输入正文",
      },
    };
  },
  created() {
    // 初始化加载获取原页面传递的参数
    this.init();
    // 回显富文本类型的值
    this.publicity.content = htmlDecode(this.publicity.content);
  },
  mounted() {},
  methods: {
    // 初始化调用
    init: function () {
      this.id = this.$route.query.id;
      this.getDetail(this.id);
    },
    // 获取中标公示项目详情
    getDetail: function (winBidId) {
      getPublicity(winBidId).then(response => {
        this.publicity = response.data;
      });
    },

    /** 文件下载 */ 
    downloadFile(row) {
      this.$confirm('是否确认下载附件？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          let baseUrl = process.env.VUE_APP_FILE_SERVER;
          let urlArr = [];
          let url = "";
          let tempUrl = this.publicity.zbgsAttach;
          if (tempUrl.indexOf(";") != -1 && tempUrl != null) { // 多个附件地址
            urlArr = tempUrl.split(";");
            for (let i = 0; i < urlArr.length; i++) {
              const iframe = document.createElement("iframe");
              iframe.style.display = "none";
              iframe.src = baseUrl + urlArr[i];
              document.body.appendChild(iframe);
              setTimeout(() => {
                    iframe.remove();
                }, 2 * 60 * 1000);
            }
          } else {
            if (tempUrl != null && tempUrl.length > 0) {
              url = baseUrl + tempUrl;
              const iframe = document.createElement("iframe");
              iframe.style.display = "none";
              iframe.src = url;
              document.body.appendChild(iframe);
            } else {
              this.$message({
                showClose: true,
                message: "该中标公示无附件可下载",
                type: 'warning',
                customClass:'mzindex'
              });
            }
          }
        }).catch(() => {
          this.$message({
            showClose: true,
            message: '文件下载失败',
            type: 'info',
            customClass:'mzindex'
          });
        });
    },

    /** 消息提示 */
    openSuccess(data) {
        this.$message({
          showClose: true,
          message: data,
          type: 'success'
        });
      },
      openError: function(msg) {
        this.$message.error(msg);
      },
      open(title, msg) {
        this.$alert(msg, title, {
          confirmButtonText: '确定',
          callback: action => {
            this.$message({
              type: 'info',
              message: msg
            });
          }
        });
      },
    /** 富文本 */
    // 失去焦点事件
    onEditorBlur (e, editor) {
    },

    // 获得焦点事件
    onEditorFocus (e) {
    },

    // 内容改变事件
    onEditorChange (e) {
    },
    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
            this.form.projectId = this.project.projectId;
            this.form.beginTime = getFormatterDate(new Date());
            addMessage(this.form).then(response => {
              // this.$form.msgSuccess("回复成功");
              this.open("投标意愿", "投标成功");
              this.$refs.form.resetFields();
              this.form.content = null;
            }).catch(err => {
                this.open("投标意愿", "投标失败，请重试");
            });
        }
      });
    },

  },
};
</script>

<style>
.solution-container {
  margin-top: -47px;
}
.solution-container .box-card {
  width: 1142px;
  height: auto;
}

.editor {
  height: 350px;
}
.el-form-item .el-button--primary {
  margin-top: 0px;
}

.el-divider__text {
  font-size: 18px;
  font-family: 微软雅黑,宋体;
  font-weight: bold
}

/**修改全局的滚动条*/
/**滚动条的宽度*/
::-webkit-scrollbar {
  width: 8px; 
 
}
/* 滚动条的滑块 */
::-webkit-scrollbar-thumb {
  background-color: #eaecf1;
  border-radius: 3px;
}

.mzindex{ 
    z-index:100000 !important; 
} 
</style>