<template>
  <Layout>
    <div class="layout-container" style="width: 100%">
      <div class="solution-page">
        <div class="container" style="text-align: center">
          <h2>招标详情</h2>
        </div>
      </div>
    </div>
    <div class="layout-container" style="width: 100%">
      <div class="breadcrumb-box">
        <div class="am-container">
          <ol class="am-breadcrumb">
            <li><router-link to="/">首页</router-link></li>
            <li class="am-active">招标详情</li>
          </ol>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="container" style="max-width: 1160px">
        <div class="section--header">
          <!-- <h2 class="section--title">招标公告展示</h2>
          <p class="section--description"></p> -->
        </div>

        <div class="solution-container">
          <el-card class="box-card">
            <!-- 招标项目详情展示 -->
            <el-divider content-position="left">招标项目详情</el-divider>
            <el-form ref="project" :model="project" label-width="100px" disabled>
              <el-form-item label="项目编号：" prop="projectNo">
                {{project.projectNo}}
              </el-form-item>
              <el-form-item label="项目名称：" prop="projectName">
                {{project.projectName}}
              </el-form-item>
              <el-form-item label="项目预算：" prop="projectBuget">
                {{project.projectBuget}} 元
              </el-form-item>
              <el-form-item label="招标单位：" prop="renderName">
                {{project.renderName}}
              </el-form-item>
              <el-form-item label="联系电话：" prop="phone">
                {{project.phone}}
              </el-form-item>
              <el-form-item label="发布时间：" prop="beginTime">
                {{project.beginTime}}
              </el-form-item>
              <el-form-item label="截止时间：" prop="endTime">
                {{project.endTime}}
              </el-form-item>

              <el-form-item label="附件：" prop="projectAttach">
                <template slot-scope="scope">
                  <el-button :disabled="disabled"
                    size="mini"
                    type="primary"
                    icon="el-icon-download"
                    @click="downloadFile(scope.row)"
                  >下载附件
                  </el-button>
                </template>
              </el-form-item>

              <el-form-item label="招标内容：">
                <div style="overflow:auto;height: 350px;" v-html="project.content"></div>
              </el-form-item>
            </el-form>
            
            <br/>

            <el-divider content-position="left">投标意愿</el-divider>
            <!-- 回复表单 -->
            <el-form ref="form" :model="form" :rules="rules" label-width="100px">
              <el-row>
                <el-col :span="24">
                  <el-form-item label="投标人" prop="author">
                    <el-input v-model="form.author" placeholder="请输入投标人" />
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="12">
                  <el-form-item label="联系电话" prop="phone">
                    <el-input v-model="form.phone" placeholder="请输入联系电话" />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item label="电子邮箱" prop="email">
                    <el-input v-model="form.email" placeholder="请输入电子邮箱" />
                  </el-form-item>
                </el-col>
              </el-row>
              
              <el-row>
                <el-col :span="8">
                  <el-form-item label="投标附件" prop="messageAttach">
                    <template slot-scope="scope">
                        <el-button size='small' type="primary" @click="openUpload(scope.row)">上传<i class="el-icon-upload el-icon--right"></i></el-button>
                      </template>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="24">
                  <el-form-item label="标书内容">
                    <!-- <editor v-model="form.content" :min-height="192" /> -->
                    <div style="overflow:auto;min-height: 450px;">
                      <quill-editor
                        ref="myLQuillEditor"
                        v-model="form.content"
                        :options="editorOption"
                        class="editor"
                        @blur="onEditorBlur"
                        @focus="onEditorFocus"
                        @change="onEditorChange">
                      </quill-editor>
                    </div>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-row>
                <el-col :span="24" align="right">
                  <el-form-item>
                    <el-button type="primary" @click="submitForm">回复</el-button>
                  </el-form-item>
                </el-col>
              </el-row>

            </el-form>
          </el-card>
        </div>
      </div>

      <el-dialog id="upLoadAlert"
               :title="uploadDialog.title"
               :visible.sync="uploadDialog.dialogVisible" width="30%">
      <!-- 1.将<el-upload>代码添加到<el-dialog>代码块中 -->
      <el-upload ref="upload"
                 class="upload-demo"
                 drag
                 multiple
                 action=""
                 accept=".pdf,.doc,.docx,.txt,.xls,.xlsx"
                 :limit="uploadDialog.fileLimit"
                 :headers="uploadDialog.headers"
                 :auto-upload="false"
                 :file-list="uploadDialog.attachList"
                 :on-change="handleChange"
                 :on-remove="removeFile"
                 :on-exceed="limitCheck">
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        <div class="el-upload__tip" slot="tip">支持.pdf / .doc / .docx / .txt / .xls / .xlsx文件，且不超过15M</div>
      </el-upload>
      <span slot="footer" class="dialog-footer">
          <el-button @click="uploadCancel">取 消</el-button>
          <el-button type="primary" @click="uploadSubmit">确 定</el-button>
      </span>
    </el-dialog>

    </div>
  </Layout>

</template>

<script>
import Layout from "@/components/common/Layout.vue";
import { getProject, addMessage, addAttach } from "@/api/render/project";
import { htmlDecode, getFormatterDate } from "@/utils/common/comm-utils";
// 局部引入富文本框
// 引入样式和quillEditor
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { download } from '@/utils/request';

// 工具栏配置项
const toolbarOptions = [
  // 加粗 斜体 下划线 删除线 -----['bold', 'italic', 'underline', 'strike']
  ["bold", "italic", "underline", "strike"],
  // 引用  代码块-----['blockquote', 'code-block']
  ["blockquote", "code-block"],
  // 1、2 级标题-----[{ header: 1 }, { header: 2 }]
  [{ header: 1 }, { header: 2 }],
  // 有序、无序列表-----[{ list: 'ordered' }, { list: 'bullet' }]
  [{ list: "ordered" }, { list: "bullet" }],
  // 上标/下标-----[{ script: 'sub' }, { script: 'super' }]
  [{ script: "sub" }, { script: "super" }],
  // 缩进-----[{ indent: '-1' }, { indent: '+1' }]
  [{ indent: "-1" }, { indent: "+1" }],
  // 文本方向-----[{'direction': 'rtl'}]
  [{ direction: "rtl" }],
  // 字体大小-----[{ size: ['small', false, 'large', 'huge'] }]
  [{ size: ["small", false, "large", "huge"] }],
  // 标题-----[{ header: [1, 2, 3, 4, 5, 6, false] }]
  [{ header: [1, 2, 3, 4, 5, 6, false] }],
  // 字体颜色、字体背景颜色-----[{ color: [] }, { background: [] }]
  [{ color: [] }, { background: [] }],
  // 字体种类-----[{ font: [] }]
  [{ font: [] }],
  // 对齐方式-----[{ align: [] }]
  [{ align: [] }],
  // 清除文本格式-----['clean']
  ["clean"],
  // 链接、图片、视频-----['link', 'image', 'video']
  ["image", "video"],
];

export default {
  name: "projectDetail",
  components: {
    Layout,
    quillEditor,
  },
  hidden: {
      type: Boolean,
      default: false
    },
  data() {
    return {
      // 【请填写功能名称】表格数据
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10,
        author: null,
        phone: null,
        email: null,
        projectId: null,
        content: null,
        beginTime: null,
      },
      disabled: false,
      // 表单参数
      form: {},
      project: {},
      /** 上传附件 ****************/
      uploadDialog: {
        // 添加属性，默认值为false,表示上传文件弹框不显示
        dialogVisible: false,
        title: "投标附件上传",
        // 设置文件列表属性attachList,需要绑定到<el-upload>元素上。默认值为空数组，表示文件列表为空
        attachList: [],
        // 运行上传文件大小，单位 M
        fileSize: 10,
        // 允许的文件类型
        // fileType: [ 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'txt' ],
        // 附件数量限制
        fileLimit: 1,
        headers: {
          'Content-Type': 'multipart/form-data'
        },
      },
      /**********************************/
      // 表单校验
      rules: {
        author: [
          { required: true, message: '请输入投标人名称', trigger: 'blur' },
          { min: 1, max: 100, message: '长度在 1 到 100 个字符', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入手机号码', trigger: 'blur' },
          { min: 11, max: 11, message: '请输入11位手机号码', trigger: 'blur' },
          {
            pattern: /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/,
            message: '请输入正确的手机号码'
          }
        ],
        email: [
          { required: false, message: '请输入邮箱号码', trigger: 'blur' },
          { min: 6, max: 50, message: '请输入正确的邮箱', trigger: 'blur' },
          {
            pattern: /^[a-z0-9]+([._\\-]*[a-z0-9])*@([a-z0-9]+[-a-z0-9]*[a-z0-9]+.){1,63}[a-z0-9]+$/,
            message: '邮箱格式不正确'
          }
        ]
      },
      // 富文本
      editorOption: {
        modules: {
          toolbar: toolbarOptions,
        },
        theme: "snow",
        placeholder: "请输入正文",
      },
    };
  },
  created() {
    // 初始化加载获取原页面传递的参数
    this.init();
    // 回显富文本类型的值
    this.project.content = htmlDecode(this.project.content);
  },
  mounted() {},
  methods: {
    // 初始化调用
    init: function () {
      this.id = this.$route.query.id;
      this.getDetail(this.id);
    },
    // 获取招标项目详情
    getDetail: function (projectId) {
      getProject(projectId).then(response => {
        let temp = response.data;
        temp.beginTime = ((temp.beginTime + "").replace(/(.{2})/g, "$1/").replace("/", "")).slice(0, -1);
        temp.endTime = ((temp.endTime + "").replace(/(.{2})/g, "$1/").replace("/", "")).slice(0, -1);
        this.project = temp;
      });
    },
    /*********************附件上传相关方法********************/
    // 文件状态改变时的钩子
    handleChange(file, fileList) { // 文件数量改变
      this.uploadDialog.attachList = fileList;
      const isLt20M = (file.size / 1024 / 1024 < this.uploadDialog.fileSize);
      if (!isLt20M) {
        // this.$message.error('上传文件大小不能超过 ' + this.uploadDialog.fileSize + ' MB!');
        this.$message({
          showClose: true,
          message: '上传文件大小不能超过 ' + this.uploadDialog.fileSize + ' MB!',
          type: 'warning',
          customClass:'mzindex'
        });
        this.uploadDialog.attachList.pop();
      }
      return isLt20M;
    },
    // 文件超出个数限制时的钩子
    limitCheck() {
      this.$message({
          showClose: true,
          message: '每次上传限制最多' + this.uploadDialog.fileLimit + '个文件',
          type: 'warning',
          customClass:'mzindex'
        });
      // this.$message.warning('每次上传限制最多' + this.uploadDialog.fileLimit + '个文件');
    },
    // 文件删除的钩子
    removeFile(file, fileList) {
      this.uploadDialog.attachList = fileList;
    },
    // 点击上传按钮
    openUpload(row) {
      this.uploadDialog.dialogVisible = true;
    },
    // 文件上传取消按钮
    uploadCancel() {
      this.uploadDialog.dialogVisible = false;
      this.uploadDialog.attachList = [];
    },
    // 文件上传提交按钮
    uploadSubmit() {
      this.uploadDialog.dialogVisible = false;
      let formData = new FormData();
      // console.log(this.uploadDialog.attachList);
      this.uploadDialog.attachList.forEach(file => {
        formData.append('files', file.raw);
      });
      // console.log(formData);
      console.log(formData.get("files"));
      // 拿取其他的信息
      // formData.append('winBidId', sessionStorage.getItem('winBidId'))
      let _self= this;
      addAttach(formData,this.uploadDialog.headers).then(response => {
        if (response.data == "error") {
          this.$message({
            showClose: true,
            message: '投标附件上传失败，请联系管理员',
            type: 'error',
            customClass:'mzindex'
          });
        } else {
          this.$message({
            showClose: true,
            message: '投标附件上传成功',
            type: 'success',
            customClass:'mzindex'
          });
          this.form.messageAttach = response.data;
        }
        _self.uploadDialog.dialogVisible = false;
        this.uploadDialog.attachList = [];
      });
    },
    /*****************************************/

    /** 文件下载 */ 
    downloadFile(row) {
      // console.log(row);
      this.$confirm('是否确认下载附件？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          let baseUrl = process.env.VUE_APP_FILE_SERVER;
          let urlArr = [];
          let url = "";
          let tempUrl = this.project.projectAttach;
          if (tempUrl.indexOf(";") != -1 && tempUrl != null) { // 多个附件地址
            urlArr = tempUrl.split(";");
            for (let i = 0; i < urlArr.length; i++) {
              const iframe = document.createElement("iframe");
              iframe.style.display = "none";
              iframe.src = baseUrl + urlArr[i];
              document.body.appendChild(iframe);
              setTimeout(() => {
                    iframe.remove();
                }, 2 * 60 * 1000);
            }
          } else {
            if (tempUrl != null && tempUrl.length > 0) {
              url = baseUrl + tempUrl;
              const iframe = document.createElement("iframe");
              iframe.style.display = "none";
              iframe.src = url;
              document.body.appendChild(iframe);
            } else {
              this.$message({
                showClose: true,
                message: "该招标公告无附件可下载",
                type: 'warning',
                customClass:'mzindex'
              });
            }
          }
      }).catch(() => {
        this.$message({
          showClose: true,
          message: '文件下载失败',
          type: 'info',
          customClass:'mzindex'
        });
      });
      
    },
    /** 消息提示 */
    openSuccess(data) {
        this.$message({
          showClose: true,
          message: data,
          type: 'success'
        });
      },
      openError: function(msg) {
        this.$message.error(msg);
      },
      open(title, msg) {
        this.$alert(msg, title, {
          confirmButtonText: '确定',
          callback: action => {
            this.$message({
              type: 'info',
              message: msg,
              customClass:'mzindex'
            });
          }
        });
      },
    /** 富文本 */
    // 失去焦点事件
    onEditorBlur (e, editor) {
    },

    // 获得焦点事件
    onEditorFocus (e) {
    },

    // 内容改变事件
    onEditorChange (e) {
    },

    /** 提交按钮 */
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
            this.form.projectId = this.project.projectId;
            this.form.beginTime = getFormatterDate(new Date());
            addMessage(this.form).then(response => {
              this.open("投标意愿", "投标成功");
              this.$refs.form.resetFields();
              this.form.content = null;
            }).catch(err => {
                this.open("投标意愿", "投标失败，请重试");
            });
        }
      });
    },

  },
};
</script>

<style scoped>
.solution-container {
  margin-top: -47px;
}
.solution-container .box-card {
  width: 1142px;
  height: auto;
}

.editor {
  height: 350px;
}
.el-form-item .el-button--primary {
  margin-top: 0px;
}

.el-divider__text {
  font-size: 18px;
  font-family: 微软雅黑,宋体;
  font-weight: bold
}

/**修改全局的滚动条*/
/**滚动条的宽度*/
::-webkit-scrollbar {
  width: 8px; 
 
}
/* 滚动条的滑块 */
::-webkit-scrollbar-thumb {
  background-color: #eaecf1;
  border-radius: 3px;
}

/* 文件上传弹框样式 */
#upLoadAlert .el-dialog__header{
  background:#EBEEF5;
  border-bottom: 1px solid#EBEEF5;
}
#upLoadAlert .el-dialog{
  text-align: left;
}
#upLoadAlert .el-upload,#upLoadAlert .el-upload .el-upload-dragger{
  width: 100%;
}
</style>

<style>
.mzindex{ 
  z-index:100000 !important; 
} 
</style>
