import { render, staticRenderFns } from "./NewsDetailsView.vue?vue&type=template&id=3923d76e&scoped=true"
import script from "./NewsDetailsView.vue?vue&type=script&lang=js"
export * from "./NewsDetailsView.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3923d76e",
  null
  
)

export default component.exports