import { render, staticRenderFns } from "./newsMore.vue?vue&type=template&id=3c639fec&scoped=true"
import script from "./newsMore.vue?vue&type=script&lang=js"
export * from "./newsMore.vue?vue&type=script&lang=js"
import style0 from "./newsMore.vue?vue&type=style&index=0&id=3c639fec&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c639fec",
  null
  
)

export default component.exports