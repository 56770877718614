


/**
 * html反转义
 * @param {*} text 
 */
export function htmlDecode(text) { 
    let tmp = document.createElement('div');
    tmp.innerHTML = text;
    const output = tmp.innerText || tmp.textContent;
    tmp = null;
    return output;
}

/**
 * 字符串转为时间格式
 * 20230712 --> 2023/07/12
 * @param str
 */
 export function stringToDateFormat(str) {
    return String(str).replace(/(.{2})/g, "$1/").replace("/", "");
}

/**
 * 2023/09/10(日期类) --> 20230910
 * @param {*} date 
 * @returns 
 */
export function getFormatterDate(date) {
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let strDate = date.getDate();
    month = month > 9 ? month : '0' + month;
    strDate = strDate > 9 ? strDate : '0' + strDate;
    return year + '' + month + '' + strDate;
}