<template>
  <Layout>
    <div class="layout-container" style="width: 100%">
      <div class="solution-page">
        <div class="container" style="text-align: center">
          <h2>招标专属页面</h2>
          <p>招标专属页面</p>
        </div>
      </div>
    </div>
    <div class="layout-container" style="width: 100%">
      <div class="breadcrumb-box">
        <div class="am-container">
          <ol class="am-breadcrumb">
            <li><router-link to="/">首页</router-link></li>
            <li class="am-active">招标</li>
          </ol>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="container" style="max-width: 1160px">
        <div class="section--header">
          <h2 class="section--title">招标公告展示</h2>
          <p class="section--description"></p>
        </div>

        <div class="solution-container">
          <el-card class="box-card">
            <el-divider content-position="left">招标公告</el-divider>
            <el-table :data="projectList" ref='table' style="width: 100%" :show-header="false">
              <el-table-column align="left" prop="projectName" min-width="520" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <span style="cursor: pointer;font-family: 宋体;color:#000000" @click="openDetail(scope.row.projectId)">{{scope.row.projectName}}</span>
                </template>
              </el-table-column>
              <el-table-column align="right" prop="beginTime" :formatter="formatter" width="180px" style="background-color: red;"/>
            </el-table>
            <!--分页-->
            <pagination
              v-show="total>0"
              :total="total"
              :page.sync="queryParams.pageNum"
              :limit.sync="queryParams.pageSize"
              @pagination="getProjectList"
            />
          </el-card>
          <el-card class="box-card">
            <el-divider content-position="left">中标公告</el-divider>
            <el-table :data="bidList" ref='table' style="width: 100%" :show-header="false">
              <el-table-column label="" align="left" prop="projectName" min-width="520" :show-overflow-tooltip="true">
                <template slot-scope="scope">
                  <span style="cursor: pointer;font-family: 宋体;color:#000000" @click="openDetailBid(scope.row.winBidId)">{{scope.row.projectName}}</span>
                </template>
              </el-table-column>
              <el-table-column label="" align="right" :formatter="dateFormatter" prop="zbgsbeginTime" width="180px" style="background-color: red;"/>
            </el-table>
            <!--分页-->
            <pagination
              v-show="bidTotal>0"
              :total="bidTotal"
              :page.sync="queryParams2.pageNum"
              :limit.sync="queryParams2.pageSize"
              @pagination="getBidList"/>
          </el-card>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/components/common/Layout";
import { listProject, listBid } from "@/api/render/project";

export default {
  name: "tenderView",
  components: { Layout },
  data() {
    return {
      // 【请填写功能名称】表格数据
      projectList: [],
      bidList: [],
      // 招标总条数
      total: 0,
      // 中标总条数
      bidTotal: 0,
      loading: true,
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 10
      },
      queryParams2: {
        pageNum: 1,
        pageSize: 10
      }
    };
  },
  mounted() {
		this.getProjectList();
    this.getBidList();
	},
  methods: {
    getProjectList: function () {
      this.loading = true;
      listProject(this.queryParams).then(response => {
        this.projectList = response.rows;
        this.total = response.total;

      }).catch(error => {
				this.$message({
					showClose: true,
					message: "网络波动，请求超时",
					type: 'warning',
					customClass: 'mzindex'
				});
			});;
    },
    getBidList: function () {
      this.loading = true;
      listBid(this.queryParams2).then(response => {
        this.bidList = response.rows;
        this.bidTotal = response.total;
      }).catch(error => {
				this.$message({
					showClose: true,
					message: "网络波动，请求超时",
					type: 'warning',
					customClass: 'mzindex'
				});
			});;
    },
    /** 解决点击下一页，滚动条回到顶部（点击下一页事件触发） */
    // this.$nextTick(() => {
    //     this.$refs.table.bodyWrapper.scrollTop = 0
    // })
    /**
     * 日期格式化显示
     * ymd --> y/m/d
     *  */ 
    formatter(row, column) {
      let date = row[column.property];
      if(date === undefined || date == null || date.length < 0){
        return ''
      }
      return (String(date).replace(/(.{2})/g, "$1/").replace("/", "")).slice(0, -1);
    },
    /**
     * year-m-d h:m:s --> y/m/d h:m
     */
    dateFormatter(row, column) {
      let date = row[column.property];
      if (date === undefined || date == null || date.length < 0) {
        return '';
      }
      return (String(date).substring(0,10)).replaceAll("-","/");
    },
    openDetail: function(projectId) {
      // 传递参数到详情页
      let routeData = this.$router.resolve({
        path: "/projectDetail",
        query: {id: projectId}
      });
      window.open(routeData.href, '_blank');
    },

    openDetailBid: function(winBidId) {
      // 传递参数到详情页
      let routeData = this.$router.resolve({
        path: "/publicityDetail",
        query: {id: winBidId}
      });
      window.open(routeData.href, '_blank');
    }

  }
};
</script>

<style scoped>
.section--title {
  margin-top: -90px;
}
.solution-container {
  margin-top: 20px;
}
.solution-container .box-card {
  width: 1142px;
  min-height: 512px;
  margin-bottom: 20px;
}

.box-card .el-table {
  font-size: 16px;
}

.el-divider__text {
  font-size: 18px;
  font-family: 微软雅黑,宋体;
  font-weight: bold
}

.TopCard .el-card__body{
  padding:0px 10px
}
/* 设置显示隐藏部分内容，按40%显示 */
.el-tooltip__popper {
    max-width: 800px;
}
</style>
