<template>
  <div class="app-container">
    <el-form  ref="queryForm" size="small" :inline="true" label-width="100px">
      <el-row :gutter="24">
        <el-col :span="12" v-for ="item in dataInfo" v-if="item.proName && item.proValue">
          <el-form-item :label="item.proName">
            {{item.proValue}}
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24" v-if="pictureList && pictureList.length > 0">
        <el-col :span="24" v-for ="item in pictureList" :key="pictureList.filePath" style="text-align: center;margin:auto 0;">
          <img :src="imgUrl + item.filePath">
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import AES from "@/utils/AES";
import {voucherlist} from "@/api/backstage/company_news";

export default {
  name: "showInfo",
  data() {
    return {
      dataInfo:[],
      pictureList:[],
      imgUrl: process.env.VUE_APP_FILE_SERVER,
    };
  },

  created() {
    let query = this.$route.query;
    if (query) {
      let code = decodeURIComponent(query.code);
      if (code) {
        code = code.replace(/\s/g,'');
        this.dataInfo = JSON.parse(AES.decrypt(code));
        if (this.dataInfo && this.dataInfo.length > 0) {
         let bizkey = this.dataInfo.find(it => it.pro == 'bizkey');
            this.getpictureList(bizkey.proValue);
        }
      }

    }
  },
  methods:{
    getpictureList(bizkey){
      if (!bizkey) {
        return ;
      }
      let queryParams = {attachId:bizkey};
      voucherlist(queryParams).then(response => {
        this.pictureList = response.rows;
      });
    },
    parseTime(time, pattern) {
      if (arguments.length === 0 || !time) {
        return null
      }
      const format = pattern || '{y}-{m}-{d} {h}:{i}:{s}'
      let date
      if (typeof time === 'object') {
        date = time
      } else {
        if ((typeof time === 'string') && (/^[0-9]+$/.test(time))) {
          time = parseInt(time)
        } else if (typeof time === 'string') {
          time = time.replace(new RegExp(/-/gm), '/').replace('T', ' ').replace(new RegExp(/\.[\d]{3}/gm), '');
        }
        if ((typeof time === 'number') && (time.toString().length === 10)) {
          date = new Date( time * 1000)
        } else if ((typeof time === 'number') && (time.toString().length === 8)) {
          let timestr = time.toString();
          date = new Date(timestr.substring(0,4),parseInt(timestr.substring(4,6)) -1,timestr.substring(6,8));
        }else {
          date = new Date(time)
        }
      }
      const formatObj = {
        y: date.getFullYear(),
        m: date.getMonth() + 1,
        d: date.getDate(),
        h: date.getHours(),
        i: date.getMinutes(),
        s: date.getSeconds(),
        a: date.getDay()
      }
      const time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, (result, key) => {
        let value = formatObj[key]
        // Note: getDay() returns 0 on Sunday
        if (key === 'a') { return ['日', '一', '二', '三', '四', '五', '六'][value] }
        if (result.length > 0 && value < 10) {
          value = '0' + value
        }
        return value || 0
      })
      return time_str
    }
  }
}
</script>
