<template>
  <Layout>
    <div class='section' style='padding-top: 20px;'>
      <div class='container'>
        <div class='section--header'>
          <div class='top-title'>
            <span class='section--title'>加入我们</span><br />
            <span>JOIN US</span>
          </div>
        </div>

        <div class='index-container'>
          <template v-for="(item,key) in dataList">
            <div :style="{width:item.width?item.width + '%':'100%',marginTop:'50px'}">
              <div class='top-title'>
                <span class='section--title' style="font-size:21px;font-weight: 700;" v-if="item.newsTitle">{{item.newsTitle}}</span><br />
                <span  v-if="item.newsSubheading"  style="font-size:18px;font-weight: 700;">{{item.newsSubheading}}</span>
                <div v-if="item.newsMainBody"  style="font-size:14px;font-weight: 500;" class="rice-item-right-text" v-html="item.newsMainBody">
                </div>
              </div>
              <img v-if="item.cover" :src="imgUrl + item.cover" alt='loading...' class="img_fluid">
            </div>
          </template>
        </div>

<!--        &lt;!&ndash; 图片banner层 &ndash;&gt;-->
<!--        <div class='banner-container'>-->
<!--          <img src="../assets/images/cdmf-img/us-img/joinusbanner.png" class="img_fluid">-->
<!--        </div>-->

        <!-- 岗位层 -->
<!--        <div class="station-container">-->
<!--          <img src="../assets/images/cdmf-img/us-img/logo2.png" alt="loading..." class="station-box-logo">-->

<!--          <div class="station-box">-->
<!--            <div class="station-box-list-img">-->
<!--              <img src="../assets/images/cdmf-img/us-img/songdian.png" class="img_fluid">-->
<!--              <img src="../assets/images/cdmf-img/us-img/nianqing.png" class="station-box-youth">-->
<!--              <img src="../assets/images/cdmf-img/us-img/nianqingen.png" class="station-box-youth-en">-->
<!--            </div>-->

<!--             <div class="station-box-list-text">-->
<!--              <div class="station-box-item">-->
<!--                <p class="station-box-item-title">收银员/店员：</p>-->
<!--                <p class="station-box-item-text">招聘2人 薪资：4K-6K</p>-->
<!--                <p class="station-box-item-text">底薪+绩效+福利</p>-->
<!--                <p class="station-box-item-text">工作职责：</p>-->
<!--                <p class="station-box-item-text">负责店面收银，协助店长做好餐厅现场管理工作。</p>-->
<!--              </div>-->

<!--              <div class="station-box-item">-->
<!--                <p class="station-box-item-title">店长：</p>-->
<!--                <p class="station-box-item-text">招聘2人 薪资：6K-9K</p>-->
<!--                <p class="station-box-item-text">底薪+绩效+福利</p>-->
<!--                <p class="station-box-item-text">工作职责：</p>-->
<!--                <p class="station-box-item-text">全面主持店面的管理工作，配合总部的各项营销策略的实施。</p>-->
<!--              </div>-->

<!--              <div class="station-box-item">-->
<!--                <p class="station-box-item-title">外卖运营：</p>-->
<!--                <p class="station-box-item-text">招聘1人 薪资：6K-8K</p>-->
<!--                <p class="station-box-item-text">底薪+绩效+福利</p>-->
<!--                <p class="station-box-item-text">工作职责：</p>-->
<!--                <p class="station-box-item-text">负责门店外卖平台基础运营，包括店铺资料的审核与上线、以及后期运营和维护。</p>-->
<!--              </div>-->
<!--            </div>-->

<!--             <div class="contact">-->
<!--              <p class="contact-phone">联系方式：131 4217 9022（于女士）微信同号</p>-->
<!--              <p class="contact-phone">地址：长沙高新开发区文轩路479号天元涉外景园C区16栋1层103号</p>-->
<!--              <img src="../assets/images/cdmf-img/us-img/weixin.png">-->
<!--            </div>-->

<!--          </div>-->
<!--        </div>-->

      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/components/common/Layout';
import {getNewsList} from "@/api/backstage/company_news";
export default {
  name: 'AboutView',
  components: { Layout },
  data() {
    return {
      dataList:[],
      imgUrl: process.env.VUE_APP_FILE_SERVER,
    };
  },

  created() {
      this.getTopPicture();
  },

  methods: {
    getTopPicture(){
      let param = { newsType: 10,newsState: 1};
      getNewsList(param).then(resp => {
        this.dataList = [];
        if (resp.rows && resp.rows.length > 0) {
          this.dataList = resp.rows;
        }
      }).catch(error => {
        this.$message({
          showClose: true,
          message: '网络延时，请刷新重试',
          type: 'warning',
          customClass: 'mzindex'
        });
      });
    },
  },
};
</script>

<style scoped>
.section {
  padding-bottom: 60px;
}

.top-title {
  text-align: center;
}

.top-title span {
  font-size: 12px;
  font-weight: 500;
}

.top-title .section--title {
  color: #A52228;
  font-family: -apple-system,BlinkMacSystemFont;
}

.img_fluid {
  width: 100%;
  height: auto;
}

.station-container {
  width: 100%;
  background: url('../assets/images/cdmf-img/us-img/joinusback.png') no-repeat;
  background-size: 100% auto;
  padding-top: 60px;
  padding-bottom: 220px;
  /* padding: 0; */
}

.station-box-logo {
  width: 25%;
  height: auto;
  margin-left: 10%;
}

.station-box {
  width: 60%;
  margin: 0px auto;
  padding: 90px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.station-box-youth {
  width: 80%;
  height: auto;
  margin: 60px auto 0px auto;
}

.station-box-youth-en {
  width: 30%;
  height: auto;
  margin: 30px auto 0px auto;
}

.station-box-list-text {
  margin-top: 60px;
}

.contact {
  padding-top:60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.contact-phone {
  font-size: 24px;
  color:#000;
  font-weight: bold;
  text-align: center;
}

.contact img {
  width: 300px;
  height: auto;
}

.station-box-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 60px;
}

.station-box-item-title {
  font-size: 28px;
  color:#E50012;
  font-weight: bold;
}

.station-box-item-text {
  font-size: 24px;
  color:#000;
  font-weight: 600;
}

/* 稻田文化层样式 */
.rice-culture-container {
  width: 95%;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0px 0px 60px 0px;
}

.rice-culture-title {
  font-size: 24px;
  color: #A52228;
  text-align: center;
  font-weight: 600;
}
</style>
