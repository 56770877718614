<template>
  <Layout>
    <div class='section' style='padding-top: 20px;'>
      <div class='container'>
        <div class='section--header'>
          <div class='top-title'>
            <span class='section--title'>产业荣誉</span><br />
            <span>INDUSTRIAL HONORS</span>
          </div>
        </div>

        <div class='index-container'>
          <template v-for="(item,key) in dataList">
            <div :style="{width:item.width?item.width + '%':'100%'}">
              <div class='top-title'>
                <span class='section--title' style="font-size:14px" v-if="item.newsTitle">{{item.newsTitle}}</span><br />
                <span  v-if="item.newsSubheading">{{item.newsSubheading}}</span>
                <div v-if="item.newsMainBody" class="rice-item-right-text" v-html="item.newsMainBody">
                </div>
              </div>
              <img :src="imgUrl + item.cover" alt='loading...' class="img_fluid">
            </div>
          </template>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/components/common/Layout';
import {getNewsList} from "@/api/backstage/company_news";
export default {
  name: 'AboutView',
  components: { Layout },
  data() {
    return {
      dataList:[],
      imgUrl: process.env.VUE_APP_FILE_SERVER,
    };
  },

  created() {
    this.getTopPicture();
  },

  methods: {
    getTopPicture(){
      let param = { newsType: 5,newsState: 1};
      getNewsList(param).then(resp => {
        this.dataList = [];
        if (resp.rows && resp.rows.length > 0) {
          this.dataList = resp.rows;
        }
      }).catch(error => {
        this.$message({
          showClose: true,
          message: '网络延时，请刷新重试',
          type: 'warning',
          customClass: 'mzindex'
        });
      });
    },
  },
};
</script>

<style scoped>
.top-title {
  text-align: center;
}

.top-title span {
  font-size: 12px;
  font-weight: 500;
}

.top-title .section--title {
  color: #A52228;
  font-family: -apple-system,BlinkMacSystemFont;
}

.honor-img {
  width: 100%;
  height: 100%;
}

.honor-container {
  max-width: 85%; 
  max-height: 530px;
  text-align: center; 
  margin: 0 auto;
  padding-top: 5%;
}

.honor-img-container {
  background: url('../assets/images/cdmf-img/about-img/rongyuback.png') no-repeat center center;
  width: 100%;
  height: 510px;
  border-radius: 30px;
  padding: 30px;
  
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.honor-img-container .item-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 20%;
}

.item-container .item-img {
  width: 100%;
  min-height: 100%;
  padding: 0 20px;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  margin-bottom: 30px;
}

.item-container .item-img img {
  height: 250px;
  width: 100%;
}

.img_fluid {
  width: 100%;
  height: auto;
}
</style>
