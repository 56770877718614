<template>
    <Layout>
        <div class="layout-container" style="width: 100%">
        </div>
        <div>
            <div class="info-list">
                <el-card class="box-card">
                    <el-table :data="newsList" ref='table' style="width: 486px;height:60vh" :show-header="false"
                        @row-click="openDetail">
                        <el-table-column align="left" prop="projectName" width="280px" :show-overflow-tooltip="true">
                            <template slot-scope="scope">
                                <span style="cursor: pointer;font-family: 宋体; color:#000000">
                                    {{ scope.row.newsTitle }}
                                  <span v-if="scope.row.newsSubheading">
                                    [{{ scope.row.newsSubheading }}]
                                  </span>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column align="right" prop="registrationTime" :formatter="dateFormatter" width="160px" />
                    </el-table>
                    <!--分页-->
                    <pagination class="pagination" v-show="total > 0" :total="total" :page.sync="queryParams.pageNum"
                        :limit.sync="queryParams.pageSize" @pagination="getNewsListInfo" small :pager-count="5" />
                </el-card>
            </div>

            <div class="info-detail">
                <div class="news-header" style="padding-bottom: 20px;">
                    <h1>{{ defaultShow.newsTitle ? defaultShow.newsTitle : '' }}</h1>
                    <div style="text-align: center;">
                        <span style="font-size: 16px; font-weight: 600; padding-right: 10px;">
                            {{ defaultShow.newsSubheading ? defaultShow.newsSubheading : '' }}
                        </span>
                        <span v-if="defaultShow.registrationTime">[发布日期：{{ defaultShow.registrationTime ? timestampToTime(defaultShow.registrationTime): ''}}]</span>
                    </div>
                </div>
                <div class="news-body" v-html="defaultShow.newsMainBody"></div>
            </div>

        </div>
    </Layout>
</template>

<script>
import Layout from "@/components/common/Layout";

import { getNewsList } from '@/api/backstage/company_news';
import { parseTime } from '@/utils/common';

export default {
    name: "newsMore",
    components: { Layout },
    data() {
        return {
            total: 0,
            loading: true,
            newsList: [],

            queryParams: {
                pageNum: 1,
                pageSize: 15,
                newsType: null,
                newsState: 1,
            },

            defaultShow: {
                newsTitle: '',
                newsSubheading: '',
                registrationTime: null,
                newsMainBody: '',
            }
        };
    },

    created() {
        this.init();
        this.getNewsListInfo();
    },
    mounted() {
    },

    methods: {
        // 初始化调用
        init () {
            this.queryParams.newsType = this.$route.query.newsType;
        },
        getNewsListInfo() {
            getNewsList(this.queryParams).then(resp => {
                this.newsList = resp.rows;
                this.total = resp.total;
                if (this.newsList) {
                    this.defaultShow = JSON.parse(JSON.stringify(this.newsList[0]));
                }
            }).catch(error => {
                this.$message({
                    showClose: true,
                    message: '网络延时，请刷新重试',
                    type: 'warning',
                    customClass: 'mzindex'
                });
            });
        },

        dateFormatter(row, column) {
            let date = row[column.property];
            if (date === undefined || date == null || date.length < 0) {
                return '';
            }
            return this.timestampToTime(Number(date));
        },

        /* 时间戳转换为时间 */
        timestampToTime(timestamp) {
            return parseTime(timestamp, '{y}/{m}/{d}');
        },

        /** 行点击事件 */
        openDetail(row) {
            this.defaultShow.newsTitle = row.newsTitle;
            this.defaultShow.newsSubheading = row.newsSubheading;
            this.defaultShow.registrationTime = row.registrationTime;
            this.defaultShow.newsMainBody = row.newsMainBody;
        }
    },
}
</script>

<style scoped>
::v-deep .section {
    padding: 0;
    padding-bottom: 20px;
}

::v-deep .main-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start !important;
}

.info-list {
    width: 577px;
    height: 75%;
    margin-top: 20px;
    margin-left: 19px;
    margin-bottom: 10px;
    float: left;
}

.info-detail {
    width: 870px;
    /* height: auto; */
    overflow-y: scroll;
    overflow-x: hidden;
    height: 876px;
    margin-top: 20px;
    margin-left: 20px;
    margin-bottom: 10px;
    text-align: center;
    text-wrap: wrap;
    float: left;
}
.info-detail::-webkit-scrollbar {
    /** 滑块宽度 */
    width: 5px;
}

.info-detail::-webkit-scrollbar-thumb {
    /** 滑块颜色 */
    background: #ccc;  
    /* 滑块圆角 */
    border-radius: 5px;
}

.list-box::-webkit-scrollbar-thumb:hover {
    /* 鼠标移入滑块变红 */
    background: #f40;
}

.list-box::-webkit-scrollbar-track {
    /* 轨道圆角 */
    border-radius: 10px;
    /* 轨道颜色 */
    background-color: #1890ff; 
}

* {
    scrollbar-width: 5px;
    scrollbar-base-color: #ccc;
    scrollbar-track-color: #ccc;
    scrollbar-arrow-color: #ccc;
}

.news-body {
    width: 860px;
    text-align: left;
}

::v-deep .news-body p  {
    text-wrap: wrap !important;
}

.pagination {
    width: 100%;
}
</style>