<template>
	<div class='header-wrapper'>
		<!-- 顶部层 -->
		<div class='header'>
			<div class='header-left'>
				<img :src='logoImg' alt='loading...'>
			</div>

			<div class='header-mid'>
				<div class='header-item'>
					<i style='color:#7c6aa6' class='contact-icon am-icon-phone'></i>
					<div class='item'>
						<strong>{{ this.hotLine }}</strong>
						<span>{{ workTime }}</span>
					</div>
				</div>
				<div class='header-item'>
					<i style='color:#7c6aa6' class='contact-icon am-icon-map-marker'></i>
					<div class='item'>
						<strong>{{ this.companyName }}&nbsp;常德市武陵区</strong>
						<span></span>
					</div>
				</div>
			</div>

			<div class='header-right'>
				<a :href='globalUrl' class=''>
					<button type='button'>后台管理</button>
				</a>
			</div>
		</div>
		<!-- 导航栏 -->
		<div class='nav-wrapper header-default'>
			<div class='nav'>
				<ul class='am-nav am-nav-pills am-nav-justify'>
					<li><router-link class='router' to='/index'>首页</router-link></li>
					<li><router-link class='router' to='/about'>关于我们</router-link></li>
					<li><router-link class='router' to='/business'>集团业务</router-link></li>
					<li><router-link class='router' to='/culture'>米粉文化</router-link></li>
					<li><router-link class='router' to='/cooperation'>招商合作</router-link></li>
					<li><router-link class='router' to='/tenders'>招投标</router-link></li>
					<li><router-link class='router' to='/news'>通知公告</router-link></li>
					<li><router-link class='router' to='/us'>联系我们</router-link></li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script>
// 导入共用组件
import AppFunctions from '@/utils/AppFunctions';
import global from '@/components/common/global.vue';
import logoImg from '@/assets/images/cdmf-img/index-img/logo.png';

export default {
	name: 'Header',
	components: {},
	props: {
		initData: {
			type: Object
		}
	},
	data() {
		return {
			AppFunctions,
			//使用
			globalUrl: global.httpUrl,
			logoImg: logoImg,
			
			companyName: '',
			hotLine: '',
			workTime: process.env.VUE_APP_WORKING_TIME,
		}
	},

	watch: {
	},

	mounted() {
	},

	created() {
		if (this.initData) {
			this.companyName = this.initData.companyName;
			this.hotLine = this.initData.hotLine;
		}
		window.addEventListener('scroll', this.toggleStickyHeader);
	},

	mounted() {
		this.toggleStickyHeader();
	},

	methods: {
		toggleStickyHeader() {
			const scrolled = document.documentElement.scrollTop;
			if (scrolled > 100) {
				AppFunctions.addClass('.header-default', 'sticky');
			} else if (scrolled <= 100) {
				AppFunctions.removeClass('.header-default', 'sticky');
			}
		},
	},

	beforeDestroy() {
		window.removeEventListener('scroll', this.toggleStickyHeader);
	}

}
</script>

<style scoped>
.router.router-link-active {
	cursor: default;
	color: #0e90d2;
}

.header {
	background-color: #A31E24;
}

.header-item {
	color: white;
}
</style>
