<template>
  <Layout>
    <div class='section' style='padding-top: 20px;'>
      <div class='container'>
        <div class='section--header'>
          <div class='top-title' style="text-align: center">
            <span class='section--title'>通知公告</span><br />
            <span>NOTICE ANNOUNCEMENT</span>
          </div>
        </div>

        <!-- 公示公告、品牌动态层 -->
        <div class='industry-container'>
          <div class="item-container left-container">
            <div class="item-title">
              <span>公示公告</span>
              <a style="float: right; padding: 3px 0; cursor: pointer;" @click.prevent="handleMore">更多></a>
            </div>
            <div class="item-list" v-for="(item, key) in news1List">
              <div class="item-list-img" v-if="item.cover">
                <img :src="imgUrl + item.cover" alt="loading..." class="img_fluid" style="height: 215px;">
              </div>
              <div class="item-list-info" :style="{width:item.cover?'48%':'100%'}">
                <div class="item-list-info-title">
                  <div class="item-list-info-title-top">
                    <p>{{ item.newsTitle }}</p>
                    <p>{{ item.newsSubheading }}</p>
                  </div>
                  <div class="item-list-info-title-bottom">
                    {{ timestampToTime(item.registrationTime) }}
                  </div>
                </div>
                <div class="item-list-info-text">
                  <div class="item-list-info-text-top text-ellipsis">
                    {{ htmlToString(item.newsMainBody) }}
                  </div>
                  <div class="item-list-info-text-bottom">
                    <a style="cursor: pointer;" @click.prevent="handleNewsDetail(item)"> <span>详情</span> > </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="item-container right-container">
            <div class="item-title">
              <span>公示公告</span>
              <a style="float: right; padding: 3px 0; cursor: pointer;" @click.prevent="handleMore">更多></a>
            </div>
            <div class="item-list" v-for="(item, key) in news2List">
              <div class="item-list-img"  v-if="item.cover">
                <img :src="imgUrl + item.cover" alt="loading..." class="img_fluid" style="height: 215px;width:215px;">
              </div>
              <div class="item-list-info" :style="{width:item.cover?'48%':'100%'}">
                <div class="item-list-info-title">
                  <div class="item-list-info-title-top">
                    <p>{{ item.newsTitle }}</p>
                    <p>{{ item.newsSubheading }}</p>
                  </div>
                  <div class="item-list-info-title-bottom">
                    {{ timestampToTime(item.registrationTime) }}
                  </div>
                </div>
                <div class="item-list-info-text">
                  <div class="item-list-info-text-top text-ellipsis">
                    {{ htmlToString(item.newsMainBody) }}
                  </div>
                  <div class="item-list-info-text-bottom">
                    <a style="cursor: pointer;" @click.prevent="handleNewsDetail(item)"> <span>详情</span> > </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 新闻列表层 -->
<!--        <div class="news-list-container">-->
<!--          <div class="news-list-title">-->
<!--            <span id="">一产要素</span>-->
<!--            <a style="cursor: pointer;" @click.prevent="handleMore">更多 &gt;</a>-->
<!--          </div>-->
<!--          <div class="news-item" v-for="(item, key) in news2List" @click="handleNewsDetail(item)">-->
<!--            <div class="news-item-left">-->
<!--              <span id="">{{ item.newsTitle }}</span>-->
<!--              <span id="">{{ item.newsSubheading }}</span>-->
<!--            </div>-->
<!--            <p class="news-item-time">{{ timestampToTime(item.registrationTime) }}</p>-->
<!--          </div>-->
<!--        </div>-->

      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from '@/components/common/Layout';

import { getNewsList } from '@/api/backstage/company_news';
import { parseTime } from '@/utils/common';

export default {
  name: 'NewsView',
  components: { Layout },
  data() {
    return {
      imgUrl: process.env.VUE_APP_FILE_SERVER,

      queryParams: {
        newsType: 9,   // 通知公告
        newsState: 1,
      },

      newsList: [],
      news1List:[],
      news2List: [],
      allNewsList: [],
    };
  },

  created() {
    this.getNewsListInfo();
  },

  methods: {
    getNewsListInfo() {
      getNewsList(this.queryParams).then(resp => {
        this.newsList = resp.rows;
        this.news1List = [];
        this.news2List = [];
        for (let i=0;i<this.newsList.length;i++) {
          if (i % 2 == 0) {
            this.news1List.push(this.newsList[i]);
          } else {
            this.news2List.push(this.newsList[i]);
          }
        }
      }).catch(error => {

      });
    },

    /* 时间戳转换为时间 */
    timestampToTime(timestamp) {
      return parseTime(timestamp, '{y}年{m}月');
    },

    /** 公告列表层点击事件 */
    handleDetail(newsId) {
      console.log('点击事件：', newsId);
    },

    /** 更多按钮操作 */
    handleMore() {
      // vue路由跳转 打开新标签页
      // let routeData = this.$router.resolve({
      //   path: "/newsMore",
      //   query: {newsType: this.queryParams.newsType}
      // });
      // window.open(routeData.href, '_blank');

      this.$router.push({
        path: "/newsMore",
        query: {newsType: this.queryParams.newsType}
      });
    },

    htmlToString(htmlStr) {
      if (!htmlStr) {
        return ;
      }
      return this.removeHtmlSign(this.escape2Html(htmlStr));
    },
    /** 详情操作按钮 */
    handleNewsDetail(item) {
      this.$router.push({
        path: "/newsDetail",
        query: {id: item.newsId}
      });
    },
    /** 将html文本转换为字符串 */
    removeHtmlSign(htmlStr) {
      return htmlStr.replace(/<\/?.+?\/?>|\r|\n|\s*/g, '');
    },
    // 转义符换成普通字符
    escape2Html(str) {
      if (!str) {
        return ;
      }
      let arrEntities = { 'lt': '<', 'gt': '>', 'nbsp': ' ', 'amp': '&', 'quot': '"' };
      return str.replace(
          /&(lt|gt|nbsp|amp|quot);/ig,
          function (all, t) {
            return arrEntities[t];
          }
      );
    },
  },
};
</script>

<style scoped>
.section {
  padding: 0;
  width: 100%;
  height: 100%;
}

img {
  vertical-align: middle;
  border-style: none;
}

.section .container {
  width: 100%;
  height: 100%;
}

/* 图片轮播层样式 */
.section .banner-container {
  margin-top: 0;
  width: 100%;
  height: 100%;
}

.banner-container .am-g {
  width: 100%;
  height: 100%;
}

::v-deep .am-g .el-carousel__container {
  height: 860px;
}

.banner-img {
  width: 100%;
  height: 100%;
}

/* 产业动态主容器层样式 */
.container .industry-container {
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 0px;
}

/* 产业动态左/右容器层样式 */
.industry-container .left-container,
.right-container {
  width: 44%;
  /* height: 75vh; */
}

/* 产业动态标题层共用样式 */
.industry-container .item-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 0px 20px 0px;
  border-bottom: 1px solid #a5a5a5;
}

.item-title span {
  font-size: 24px;
  font-weight: bold;
}

.item-title a {
  font-size: 20px;
}

.item-list {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 30px;
}

.item-list-img {
  width: 49%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.item-list-info {
  width: 48%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.img_fluid {
  width: 100%;
  height: auto;
}

.item-list-info-title,
.item-list-info-text {
  display: flex;
  flex-direction: column;
  padding: 15px;
  background-color: #BC0B21;
  width: 100%;
}

.item-list-info-title p {
  font-size: 14px;
  color: #DFBE81;
  margin: 0px;
}

.item-list-info-title-top {
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0px 0px 5px 0px;
  border-bottom: 1px solid #ccc;
}

.p-text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.item-list-info-title-bottom {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  margin: 0px;
  color: #fff;
  padding-top: 5px;
}

.item-list-info-text-top {
  color: #Fff;
  height: 94px;
  font-size: 14px;
  padding: 0px 0px 5px 0px;
  border-bottom: 1px solid #ccc;
}

/** 超出省略号 */
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.item-list-info-text-bottom {
  padding: 1px 0 0px 0px;
}

.item-list-info-text-bottom a {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: 14px;
}

.item-list-info-text-bottom a:hover {
  color: blue;
}


/* 视频层样式 */
.video-container {
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0px auto;
  padding: 0px 0px 60px 0px;
}

.video-left {
  width: 60%;
  display: flex;
}

.video-left video {
  width: 100%;
  height: auto;
}

.video-right {
  width: 40%;
  background-color: #A52228;
}

.video-right a {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.video-right img {
  width: 78%;
  height: auto;
}

/* 追溯层样式 */
.trace-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 60px 0px;
}

.trace-item-box {
  width: 85%;
  border-radius: 30px;
  background-color: #A52228;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.trace-item-box p {
  font-size: 24px;
  color: #DFBE81;
  margin: 0px;
}

/* 技术公示层样式 */
.publicity-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 60px 0px;
}

.publicity-item-box {
  width: 85%;
  border-radius: 30px;
  background-color: #A52228;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.publicity-item-box p {
  font-size: 24px;
  color: #DFBE81;
  margin: 0px;
}

/* 产业联盟层样式 */
.alliance-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 0px 60px 0px;
}

.alliance-item-box {
  width: 85%;
  border-radius: 30px;
  background-color: #A52228;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0px;
}

.alliance-item-box P {
  font-size: 24px;
  color: #DFBE81;
  margin: 0px;
}

.alliance-item-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 80%;
  margin-top: 15px;
}

.a-item {
  width: 15%;
}

.supervise-container {
  width: 85%;
  margin: 0px auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px 0px 60px 0px;
}

.item-supervise,
.item-report {
  width: 49%;
  background-color: #A52228;
  border-radius: 30px;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.item-supervise p,
.item-report p {
  font-size: 24px;
  color: #DFBE81;
  margin: 0px;
}

</style>
